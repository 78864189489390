import React from 'react';
import { Translation, Trans } from 'react-i18next';
import { Link, Box, FlexBox, Typography } from '@vp/swan';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import PaperJam from '../../images/paper-jam.svg';

const messages = defineMessages({
  whoops: 'Whoops – something unexpected just happened.',
  unauthorized: 'Oh no! You are not supposed to view this.',
  notFound: 'Whoops - It looks like this link is broken.',
  sorry: 'Sorry about the hiccup. <br/> Let’s get you back on track.',
  hint: 'Make sure you are logged in to the correct account.',
  backToHomepage: 'Get back to our homepage',
  needHelp: 'Need help?',
  callUs: 'Call us at: __carePhoneNumber__',
  reachOutToTheCustomer:
    'Action required, please reach out to the customer and make him open the builder',
});

interface ErrorProps {
  errorCode?: number;
}

const ErrorPage: React.FC<ErrorProps> = (props) => {
  const carePhoneNumber = window.csbData.care.phoneNumber;
  const careSchedule = window.csbData.care.schedule;
  let errorMessage = messages.whoops.id;
  switch (props.errorCode) {
    case 404:
      errorMessage = messages.notFound.id;
      break;
    case 403:
      errorMessage = messages.unauthorized.id;
      break;
    case 409:
      errorMessage = messages.reachOutToTheCustomer.id;
      break;
  }
  return (
    <Translation>
      {(t) => {
        return (
          <FlexBox alignItems="center" flexDirection="column" marginY={5} marginX={7}>
            <PaperJam />
            <Box align="center">
              <Typography component="h1" fontSize={5} padding={6} align="center" weight="bold">
                {t(errorMessage)}
              </Typography>
              <Typography component="h2" fontSize={2} paddingX={7} paddingY={5} align="center">
                <Trans i18nKey={messages.sorry.id} />
                <br />
                {props.errorCode === 403 && t(messages.hint.id)}
              </Typography>

              <Box marginY={12}>
                <Typography component="h2">
                  <Link to="/">{t(messages.backToHomepage.id)}</Link>
                </Typography>
              </Box>

              <Box marginY={12}>
                <Typography fontSize={2}>
                  <strong>{t(messages.needHelp.id)}</strong>
                  {carePhoneNumber && <p>{t(messages.callUs.id, { carePhoneNumber })}</p>}
                  {careSchedule &&
                    careSchedule.map((timeSlot) => {
                      return <Typography key={timeSlot}>{timeSlot}</Typography>;
                    })}
                </Typography>
              </Box>
            </Box>
          </FlexBox>
        );
      }}
    </Translation>
  );
};

export default ErrorPage;
