import React, { useState } from 'react';
import { Card, FlexBox } from '@vp/swan';
import EqualsIcon from '../../../images/equals.svg';

import '../LinkCard.scss';

// Matches the padding between each card and the editor-container element
const CARD_PADDING = 24;

interface LinkCardPreviewProps {
  text: string;
}

// This component is a simplified clone of LinkCard.tsx.
// It's only rendered while dragging blocks to reorder them.
const LinkCard: React.FC<LinkCardPreviewProps> = (props: LinkCardPreviewProps) => {
  const [previewWidth] = useState(document.getElementById('editor-container')?.offsetWidth);

  return (
    <Card
      marginBottom={5}
      className="link-card link-card-drag-preview"
      style={{ width: `${(previewWidth || 400) - CARD_PADDING * 2}px` }}
    >
      <FlexBox justifyContent={'flex-start'} className="card-content">
        <div className="feature-icon-container">
          <EqualsIcon className="feature-text-icon" />
        </div>
        <div className="card-text">{props.text}</div>
      </FlexBox>
    </Card>
  );
};

export default LinkCard;
