import React, { useState, useContext, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import Joyride, { Placement, CallBackProps, ACTIONS, EVENTS } from 'react-joyride';
import { useTranslation, Trans } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { Button } from '@vp/swan';
import WelcomeDialog from './WelcomeDialog';
import CustomTooltip from './CustomTooltip';
import Pages from '../data/Pages';
import RouterContext from '../utils/RouterContext';
import OnboardingContext from '../utils/OnboardingContext';
import { StepKind } from '../../enums/PageEnums';
import { SessionContext } from '../hooks/SessionContext';

import './Onboarding.scss';

const messages = defineMessages({
  endTour: 'End Tour',

  customizationStepTitle: 'Landing page customization',
  customizationStepContent:
    'Here is where you’ll add features and modify the look & feel of your profile.',

  previewStepTitle: 'Landing page preview',
  previewStepContent:
    'This preview will update as you add features and make changes. You’ll be able to interact with all parts of the landing page.',

  addFeatureStepTitle: 'Add your first feature',
  addFeatureStepContent: 'Click the highlighted feature to start building your landing page.',

  editFeatureStepTitle: 'Edit your feature',
  editFeatureStepContent:
    'After clicking a feature, you’ll be taken to the details tab where you enter the required information.',
  editFeatureRequiredAction: 'Click add content button to continue',

  includedFeaturesStepTitle: 'Change, Reorder & Delete',
  includedFeaturesStepContent:
    'Added features will move to the top of the feature list. They can be changed, reordered and deleted by using the icons.',

  tabsStepTitle: 'Style your landing page',
  tabsStepContent: 'Click the style tab to update the look of your landing page.',

  layoutStepTitle: 'Change layouts, colors & more',
  layoutStepContent:
    'Here is where you can choose a variety of styling options for your landing page.',

  lastStepTitle: 'Tour Completion!',
  lastStepContent:
    'The tour is over but your page creation isn’t. Add features and modify styles until your profile feels complete and then publish.',
  lastStepEmojiLabel: 'Celebration emoji',
});

enum Device {
  'mobile',
  'desktop',
}
// not specifying a device makes the step show in both

interface OnboardingProps {
  currentStep: number;
  setStep: (step: number) => void;
  setCurrentTab: (tab: StepKind) => void;
  boom: () => void;
  scrollEditorTop: () => void;
  setIsMobileEditorOpen: (open: boolean) => void;
}

const Onboarding: React.FC<OnboardingProps> = (props) => {
  const { t } = useTranslation();
  const { siteData } = useContext(SessionContext);
  const { changePage } = useContext(RouterContext);
  const { setBackButtonDisabled } = useContext(OnboardingContext);
  const [run, setRun] = useState<boolean>(true);

  const endTour = () => {
    setRun(false);
    window.isMobile && props.setIsMobileEditorOpen(false);
    changePage(Pages.Main);
    props.setCurrentTab(StepKind.Features);
    props.scrollEditorTop();
  };

  useEffect(() => {
    if (!isEmpty(siteData) && props.currentStep === 0) {
      endTour();
    }
  }, [siteData]);

  const steps = [
    {
      target: 'body',
      placement: 'center' as Placement,
      content: null, // content will be in the WelcomeDialog component
      tooltipComponent: WelcomeDialog,
      spotlightClicks: false,
      floaterProps: { styles: { floater: { width: '100%' } } },
    },
    {
      device: Device.desktop,
      target: '.editor-container',
      placement: 'right' as Placement,
      title: t(messages.customizationStepTitle.id),
      content: t(messages.customizationStepContent.id),
      floaterProps: { styles: { floater: { marginTop: -320, marginLeft: 20 } } },
      beforeHook: () => {
        props.scrollEditorTop();
      },
    },
    {
      device: Device.mobile,
      target: '.mobile-add-button',
      placement: 'top' as Placement,
      title: t(messages.customizationStepTitle.id),
      content: t(messages.customizationStepContent.id),
      spotlightClicks: true,
    },
    {
      device: Device.desktop,
      target: '.site-preview-frame-container',
      placement: 'right' as Placement,
      title: t(messages.previewStepTitle.id),
      content: t(messages.previewStepContent.id),
      spotlightClicks: true,
    },
    {
      target: '.link-card.has-hint',
      placement: 'right' as Placement,
      title: t(messages.addFeatureStepTitle.id),
      content: t(messages.addFeatureStepContent.id),
      spotlightClicks: false,
      delay: window.isMobile ? 350 : 0,
      beforeHook: () => {
        window.isMobile && props.setIsMobileEditorOpen(true);
        changePage(Pages.Main);
        props.scrollEditorTop();
      },
    },
    {
      target: '.editor-container',
      placement: window.isMobile ? undefined : ('right' as Placement),
      title: t(messages.editFeatureStepTitle.id),
      content: t(messages.editFeatureStepContent.id),
      hideFooter: true,
      requiredAction: t(messages.editFeatureRequiredAction.id),
      spotlightClicks: true,
      beforeHook: () => {
        changePage(Pages.EmailUs);
        props.scrollEditorTop();
        setBackButtonDisabled(true);
      },
    },
    {
      device: Device.mobile,
      target: '.site-preview-frame-container',
      placement: 'bottom' as Placement,
      title: t(messages.previewStepTitle.id),
      content: t(messages.previewStepContent.id),
      spotlightClicks: true,
    },
    {
      target: '.used-cards-section',
      placement: 'right' as Placement,
      title: t(messages.includedFeaturesStepTitle.id),
      content: t(messages.includedFeaturesStepContent.id),
      delay: window.isMobile ? 350 : 0,
      beforeHook: () => {
        window.isMobile && props.setIsMobileEditorOpen(true);
        changePage(Pages.Main);
        props.setCurrentTab(StepKind.Features);
        props.scrollEditorTop();
      },
    },
    {
      target: window.isMobile ? '.mobile-tab-styles' : '.button-bar-section',
      placement: 'right' as Placement,
      title: t(messages.tabsStepTitle.id),
      content: t(messages.tabsStepContent.id),
      spotlightClicks: true,
      beforeHook: () => {
        props.setCurrentTab(StepKind.Features);
        props.scrollEditorTop();
      },
    },
    {
      target: '.themes-page-section',
      placement: 'right' as Placement,
      title: t(messages.layoutStepTitle.id),
      content: t(messages.layoutStepContent.id),
      spotlightClicks: true,
      disableScrolling: false,
      beforeHook: () => {
        props.setCurrentTab(StepKind.Style);
      },
    },
    {
      target: 'body',
      placement: 'center' as Placement,
      title: (
        <>
          <Trans i18nKey={messages.lastStepTitle.id} />
          <span onClick={props.boom} role="img" aria-label={t(messages.lastStepEmojiLabel.id)}>
            🎉
          </span>
        </>
      ),
      content: t(messages.lastStepContent.id),
      spotlightClicks: true,
      disableScrolling: false,
      beforeHook: () => {
        props.boom();
      },
    },
  ];

  const filteredSteps = steps.filter((step) => {
    return step.device !== (window.isMobile ? Device.desktop : Device.mobile);
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    if (data.type === EVENTS.STEP_AFTER) {
      let upcomingIndex = 0;

      if (data.action === ACTIONS.NEXT) {
        upcomingIndex = data.index + 1;
      } else if (data.action === ACTIONS.PREV) {
        upcomingIndex = data.index - 1;
      }

      const upcomingStepBeforeHook = filteredSteps?.[upcomingIndex]?.beforeHook;
      upcomingStepBeforeHook?.();

      const delayBeforeNextStep = filteredSteps?.[upcomingIndex]?.delay || 0;
      setTimeout(() => {
        props.setStep(upcomingIndex);
      }, delayBeforeNextStep);
    }

    if (data.type === EVENTS.TOUR_END || data.action === ACTIONS.STOP) {
      setBackButtonDisabled(false);
      endTour();
    }
  };

  return (
    <>
      <Joyride
        run={run}
        steps={filteredSteps}
        tooltipComponent={CustomTooltip}
        showSkipButton={true}
        continuous={true}
        disableScrolling={true}
        disableScrollParentFix={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        stepIndex={props.currentStep}
        floaterProps={{ styles: { arrow: { length: 7, spread: 14 } } }}
        callback={handleJoyrideCallback}
      />
      {run && !window.isMobile && props.currentStep > 0 && (
        <Button className="onboarding-end-tour-button" onClick={endTour}>
          {t(messages.endTour.id)}
        </Button>
      )}
    </>
  );
};

export default Onboarding;
