import { FormDataModel, FormSchema } from '../components/utils/FormUtils';
import { isEmail } from '../components/utils/ValidationUtils';

type EmailUsModel = FormDataModel<{
  label: string;
  email: string;
}>;

export const emailUsSchema: FormSchema<EmailUsModel> = {
  label: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  email: {
    type: 'text',
    required: true,
    validator: isEmail,
    validatorError: 'client.components.utils.ValidationUtils.email',
  },
};

export default EmailUsModel;
