import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useLocale } from '@vp/digital-locale-lib';
import MemoizedBookend from './MemoizedBookend';
import { SessionContext } from '../hooks/SessionContext';
import { publish } from '../../api';
import PublishModal from './PublishModal';
import PublishFailureModal from './PublishFailureModal';
import BookendHeaderButton from './BookendHeaderButton';
import { getNextStepUrl } from '../../api/siteFlow';

import './BookendsHeader.scss';

interface Props {
  boom: () => void;
}
const messages = defineMessages({
  publish: 'Publish',
  configureLater: 'Configure later',
  saveAndContinue: 'Save and continue',
});

const BookendsHeader: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { identifier: locale } = useLocale();
  const { userData, siteData } = useContext(SessionContext);
  const [modalStatus, setModalStatus] = useState<string | null>(null);
  const [headerButtonLabel, setHeaderButtonLabel] = useState<string>(getHeaderButtonLabel());

  function getHeaderButtonLabel() {
    return userData.connectedProduct.benefitAltId
      ? t(messages.publish.id)
      : siteData.length === 0
      ? t(messages.configureLater.id)
      : t(messages.saveAndContinue.id);
  }

  useEffect(() => {
    setHeaderButtonLabel(getHeaderButtonLabel());
  }, [siteData]);

  const handlePublish = async () => {
    try {
      await publish(userData.connectedProduct.altId, siteData!);
    } catch (e) {
      setModalStatus('failure');
      return;
    }
    props.boom();
    setModalStatus('success');
  };

  const handleRedirect = async (): Promise<void> => {
    let url;
    try {
      url = await getNextStepUrl('ConnectedProductsStudio', locale);
      url = url.replace('{workId}', userData.connectedProduct.workId!); // TODO setWorkId as mandatory
    } catch (e) {
      console.error('Error while calling SiteFlow', e);
      return;
    }
    window.location.href = url;
  };

  const handleHeaderAction = async () => {
    if (userData.connectedProduct.siteId) {
      await handlePublish();
    } else if (!userData.connectedProduct.benefitAltId) {
      await handleRedirect();
    } else {
      // TODO VBC-913
      // check the siteId one more time ?
      // show a nice translated msg to the customer ?
      alert('Site provisioning is not completed yet. Please try again later');
    }
  };

  return (
    <>
      <div className="bookends-header-container">
        <MemoizedBookend />
        <BookendHeaderButton action={handleHeaderAction} text={headerButtonLabel} />
      </div>
      {modalStatus === 'success' && (
        <PublishModal
          isModalOpened={!!modalStatus}
          setModalStatus={setModalStatus}
          publishedDomain={`${window.vbcConfig.scanBaseUrl}/${userData.connectedProduct.altId}`}
        />
      )}

      {modalStatus === 'failure' && (
        <PublishFailureModal isModalOpened={!!modalStatus} setModalStatus={setModalStatus} />
      )}
    </>
  );
};

export default BookendsHeader;
