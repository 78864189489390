import React, { useState } from 'react';
import { Button, Spinner } from '@vp/swan';

interface PublishButtonProps {
  action: () => void;
  text: string;
}

const BookendHeaderButton: React.FC<PublishButtonProps> = (props) => {
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <div className="header-button">
      <Button
        skin="primary"
        disabled={isProcessing}
        onClick={async () => {
          setIsProcessing(true);
          try {
            await props.action();
          } finally {
            setIsProcessing(false);
          }
        }}
      >
        {isProcessing && <Spinner accessibleText="Loading" />}
        {props.text}
      </Button>
    </div>
  );
};

export default BookendHeaderButton;
