import React, { useContext, useState } from 'react';
import Page from '../ui/Page';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import UploadPDFModel, { uploadPDFSchema } from '../../models/UploadPDFModel';
import PageBaseProps from './PageBase';
import { StandardForm, FormLabel, FormField } from '@vp/swan';
import FileInput from '../ui/fileInput/FileInput';
import { AssetStoreContext } from '../hooks/AssetStoreProvider';
import {
  FormStateModel,
  inputGenerators,
  isValidForm,
  preventEnterSubmission,
} from '../utils/FormUtils';
import isEmpty from 'lodash/isEmpty';

const messages = defineMessages({
  title: 'PDF Upload',
  desc: 'Add a menu or form for your customers to view and download.',
  label: 'What do you want to name this section?',
  uploadAFile: 'Upload a file',
});

const UploadPDFPage: React.FC<PageBaseProps<UploadPDFModel>> = (props) => {
  const { t } = useTranslation();
  const { uploadAsset } = useContext(AssetStoreContext);
  const initialData = props.formData || {
    label: '',
    fileName: '',
    url: '',
  };
  const [uploadPDF, setUploadPDF] = useState<FormStateModel<UploadPDFModel>>({
    data: initialData,
    errors: {
      label: null,
      fileName: null,
      url: null,
    },
    dirty: {
      label: !isEmpty(initialData.label),
      fileName: !isEmpty(initialData.fileName),
      url: !isEmpty(initialData.url),
    },
  });

  const handleSubmit = () => {
    props.onSubmit({
      key: 'uploadPDFModel',
      data: uploadPDF.data,
    });
  };

  const enableSubmit = (): boolean => {
    return isValidForm<UploadPDFModel>(uploadPDF.data, uploadPDFSchema);
  };

  const handleUpload = async (file: File) => {
    try {
      const fileUrl = await uploadAsset(file);
      if (fileUrl !== null) {
        setUploadPDF({
          ...uploadPDF,
          errors: {
            ...uploadPDF.errors,
            url: null,
          },
          data: { label: uploadPDF.data.label, fileName: file.name, url: fileUrl },
        });
      }
    } catch (e) {
      const msg = 'Error while uploading file ' + e.message;
      console.error(msg); // eslint-disable-line no-console
      setUploadPDF({
        ...uploadPDF,
        errors: {
          ...uploadPDF.errors,
          url: t('client.components.utils.ValidationUtils.genericError'),
        },
      });
      throw new Error(msg);
    }
  };

  const handleDelete = () => {
    setUploadPDF({
      data: { label: uploadPDF.data.label, fileName: '', url: '' },
      errors: {
        ...uploadPDF.errors,
        url: null,
      },
      dirty: {
        label: !isEmpty(uploadPDF.data.label),
        fileName: true,
        url: true,
      },
    });
  };

  const idPrefix = `client.components.pages.UploadPDFPage`;
  const inputFields = Object.keys(uploadPDF.data)
    .filter((key) => uploadPDFSchema[key as keyof UploadPDFModel].type !== 'ignore')
    .map((key) => {
      const inputType = uploadPDFSchema[key as keyof UploadPDFModel].type;
      const inputGenerator = inputGenerators[inputType];
      return inputGenerator<UploadPDFModel>(
        uploadPDF,
        uploadPDFSchema,
        key as keyof UploadPDFModel,
        t(`${idPrefix}.${key}`),
        '',
        t,
        setUploadPDF
      );
    });

  return (
    <Page
      title={t(messages.title.id)}
      desc={t(messages.desc.id)}
      enableSubmit={enableSubmit}
      handleSubmit={handleSubmit}
      isNewBlock={!props.formData}
    >
      <StandardForm className="vbc-form" onKeyPress={preventEnterSubmission}>
        {inputFields}
        <FormField>
          <FormLabel className={'vbc-form-label-required'}>{t(messages.uploadAFile.id)}</FormLabel>
          <FileInput
            fileName={uploadPDF.data.fileName as string}
            url={uploadPDF.data.url as string}
            onUpload={handleUpload}
            onDelete={handleDelete}
          />
          {uploadPDF.errors.url && <p className="input-group-error-text">{uploadPDF.errors.url}</p>}
        </FormField>
      </StandardForm>
    </Page>
  );
};

export default UploadPDFPage;
