import React from 'react';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';
import {
  FlexBox,
  Button,
  ModalDialog,
  ModalDialogButtons,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalDialogTitle,
  ModalDialogCloseButton,
} from '@vp/swan';

import './PublishModal.scss';

interface Props {
  isModalOpened: boolean;
  setModalStatus: (status: string | null) => void;
}
const messages = defineMessages({
  congrats:
    "Oops! We're having trouble publishing your site. Please try again or contact Vistaprint customer support.",
  close: 'Close',
});

const PublishModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isModalOpened, setModalStatus } = props;

  return (
    <ModalDialog
      isOpen={isModalOpened}
      onRequestDismiss={() => setModalStatus(null)}
      className="publish-modal"
    >
      <ModalDialogContent>
        <ModalDialogCloseButton visuallyHiddenLabel={t(messages.close.id)} />
        <ModalDialogHeader>
          <ModalDialogTitle>
            <div className="title-content">{t(messages.congrats.id)}</div>
          </ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogFooter>
          <FlexBox justifyContent="center" alignItems="center">
            <ModalDialogButtons>
              <Button skin="primary" onClick={() => setModalStatus(null)}>
                {t(messages.close.id)}
              </Button>
            </ModalDialogButtons>
          </FlexBox>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  );
};
export default PublishModal;
