import { FormDataModel } from '../components/utils/FormUtils';
import CroppedImage from './CroppedImageModel';

export enum BackgroundKind {
  color = 'Color',
  image = 'Image',
  pattern = 'Pattern',
}

// We have 3 different ways the background could be indicated. Use a Discriminated Union to represent them
// for more info, see: https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#discriminating-unions=

export interface BackgroundColor {
  kind: BackgroundKind.color;
  color: string;
}

export interface BackgroundImage {
  kind: BackgroundKind.image;
  image: CroppedImage;
}

export interface BackgroundPattern {
  kind: BackgroundKind.pattern;
  patternURL: string;
}

export type Background = BackgroundColor | BackgroundImage | BackgroundPattern;

//Buttons
export enum ButtonKind {
  square = 'square',
  rounded = 'rounded',
  pill = 'pill',
  squareOutline = 'squareOutline',
  roundedOutline = 'roundedOutline',
  pillOutline = 'roundedOutline',
}

export type ButtonDesign = {
  shape: ButtonKind;
  color: string;
  textColor: string;
};

type ThemesModel = FormDataModel<{
  layout: string;
  background: Background;
  buttonDesign: ButtonDesign;
}>;

export default ThemesModel;
