import React, { useRef, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { Spinner, FlexBox } from '@vp/swan';

import FileSvg from '../../../images/file.svg';
import UploadSvg from '../../../images/upload.svg';

import './FileInput.scss';

interface Props {
  fileName: string | undefined;
  url: string | undefined;
  onUpload: (file: File) => void;
  onDelete: () => void;
}
const messages = defineMessages({
  uploadFileIcon: 'Upload file icon',
  pdfIcon: 'PDF uploaded icon',
  loading: 'File loading',
});
const FileInput: React.FC<Props> = ({ fileName, onUpload, onDelete }) => {
  const [loading, setLoading] = useState(false);
  const inputFile = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (fileName) {
      setLoading(false);
    }
  }, [fileName]);

  function onUploadButtonClick() {
    if (inputFile.current) {
      inputFile.current.click();
    }
  }

  async function onFileUpload(event: ChangeEvent<HTMLInputElement>) {
    const newFile = event.target.files![0] as File;
    if (newFile) {
      setLoading(true);
      try {
        await onUpload(newFile);
      } catch (error) {
        setLoading(false);
      }
    }
  }

  return (
    <div>
      {fileName ? (
        <React.Fragment>
          {onDelete && (
            <div className="remove-icon" onClick={() => onDelete()}>
              ✕
            </div>
          )}
          <FlexBox className="upload-file-box-uploaded">
            <span className="svg-icon">
              <FileSvg alt={t(messages.pdfIcon.id)} />
            </span>
            <span className="filename">{fileName}</span>
          </FlexBox>
        </React.Fragment>
      ) : loading ? (
        <span className="upload-file-box">
          <Spinner accessibleText={t(messages.loading.id)} />
        </span>
      ) : (
        <FlexBox className="upload-file-box" onClick={onUploadButtonClick}>
          <input
            style={{ display: 'none' }}
            type="file"
            onChange={onFileUpload}
            ref={inputFile}
            accept={'.pdf'}
          />
          <span className="svg-icon">
            <UploadSvg alt={t(messages.uploadFileIcon.id)} />
          </span>
        </FlexBox>
      )}
    </div>
  );
};
export default FileInput;
