import React from 'react';
import { SelectionSet, Column, GridContainer, Row } from '@vp/swan';
import ThemeLayoutButton from '../ThemeLayoutButton';
import { ButtonKind } from '../../../../models/ThemesModel';
import './ButtonShape.scss';

interface Props {
  shape: ButtonKind;
  t: (key: string) => string;
  onButtonShapeChange: (shape: ButtonKind) => void;
}

const ButtonShape: React.FC<Props> = ({ shape, t, onButtonShapeChange }) => {
  const buttonShapeList = Object.keys(ButtonKind).map((buttonKind: string, index) => (
    <Column span={4} spanSm={4} spanXs={4} key={index} style={{ padding: '12px 0px' }}>
      <ThemeLayoutButton
        accessibleDescription={`client.components.ui.theme.buttons.ThemeButtons.${buttonKind}Description`}
        value={buttonKind}
        imageURL={`${window.vbcConfig.host}/images/${buttonKind}.svg`}
        imageAlt={buttonKind}
        tileName={t(`client.components.ui.theme.buttons.ThemeButtons.${buttonKind}`)}
      />
    </Column>
  ));

  return (
    <div className="shapes-container">
      <SelectionSet
        variant="single-select"
        selectedValue={shape}
        onSelectedValueChange={(newSelectedValue) => {
          onButtonShapeChange(newSelectedValue as ButtonKind);
        }}
      >
        <GridContainer>
          <Row>{buttonShapeList}</Row>
        </GridContainer>
      </SelectionSet>
    </div>
  );
};
export default ButtonShape;
