import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import PageBaseProps from './PageBase';
import Page from '../ui/Page';
import AddressModel, {
  addressSchema,
  getMapStyleByValue,
  MapStyle,
} from '../../models/AddressModel';
import DropDownField from '../ui/DropDownField';
import { Checkbox, FlexBox, FormField, FormLabel, FormInputGroup, StandardForm } from '@vp/swan';
import {
  FormStateModel,
  isValidForm,
  preventEnterSubmission,
  inputGenerators,
  FormErrorModel,
} from '../utils/FormUtils';
import isEmpty from 'lodash/isEmpty';
import { geoCodeAddress } from '../../api';
import { SessionContext } from '../hooks/SessionContext';

const messages = defineMessages({
  label: 'What do you want to name this section?',
  businessName: 'Business Name',
  title: 'Address Details',
  desc: 'Display a mini map of your location and allow customers to get directions.',
  address: 'Street Address',
  mapStyle: 'Map style',
  enableMap: 'Show map preview',
  roadmap: 'Normal',
  satellite: 'Satellite',
  terrain: 'Terrain',
  hybrid: 'Hybrid',
  invalid: "Can't find the address",
  addressPlaceHolder: 'ex:123 street,city,state postcode',
});

const AddressPage: React.FC<PageBaseProps<AddressModel>> = (props) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language.substring(0, 2);
  const { userData } = useContext(SessionContext);

  const initialData = props.formData || {
    label: t(messages.title.id).toString(),
    businessName: '',
    address: '',
    mapStyle: MapStyle.roadmap,
    enableMap: true,
    lat: 0,
    lng: 0,
  };
  const [addressModel, setAddressModel] = useState<FormStateModel<AddressModel>>({
    data: initialData,
    errors: {
      label: null,
      businessName: null,
      address: null,
      mapStyle: null,
      enableMap: null,
      lat: null,
      lng: null,
    },
    dirty: {
      label: !isEmpty(initialData.label),
      businessName: !isEmpty(initialData.businessName),
      address: !isEmpty(initialData.address),
      mapStyle: !isEmpty(initialData.mapStyle),
      enableMap: false,
      lat: false,
      lng: false,
    },
  });

  const handleSubmit = async () => {
    const address = addressModel.data.address as string;

    try {
      const { lat, lng } = await geoCodeAddress(userData.connectedProduct.altId, address, language);
      addressModel.data.lat = lat;
      addressModel.data.lng = lng;
      props.onSubmit({ key: 'addressModel', data: addressModel.data });
    } catch (error) {
      console.error('[Address Validation] invalid address', error);
      const newAddressError: FormErrorModel<AddressModel> = {
        ...addressModel.errors,
        ...{
          address: messages.invalid.id,
        },
      };
      setAddressModel({ ...addressModel, errors: newAddressError });

      // Throw an error to stop navigation to Main Page
      throw 'Failed Address Validation';
    }
  };

  const enableSubmit = (): boolean => {
    return isValidForm<AddressModel>(addressModel.data, addressSchema);
  };

  const handleMapStyleChange = (selected: string) => {
    const newModel = { ...addressModel };
    newModel.data.mapStyle = getMapStyleByValue(selected);
    setAddressModel(newModel);
  };

  const idPrefix = `client.components.pages.AddressPage`;
  const inputFields = Object.keys(addressModel.data)
    .filter((key) => addressSchema[key as keyof AddressModel].type !== 'ignore')
    .map((key) => {
      const inputType = addressSchema[key as keyof AddressModel].type;
      const inputGenerator = inputGenerators[inputType];
      return inputGenerator<AddressModel>(
        addressModel,
        addressSchema,
        key as keyof AddressModel,
        t(`${idPrefix}.${key}`),
        '',
        t,
        setAddressModel,
        key === 'address' ? t(messages.addressPlaceHolder.id) : ''
      );
    });

  return (
    <Page
      title={t(messages.title.id)}
      desc={t(messages.desc.id)}
      enableSubmit={enableSubmit}
      handleSubmit={handleSubmit}
      isNewBlock={!props.formData}
    >
      <StandardForm className="vbc-form" onKeyPress={preventEnterSubmission}>
        {inputFields}
        <FlexBox flexDirection="row" component={FormField}>
          <FormLabel> {t(messages.enableMap.id)}</FormLabel>
          <FormInputGroup>
            <Checkbox
              ml={4}
              checked={addressModel.data.enableMap as boolean}
              aria-required="false"
              aria-label={t(messages.enableMap.id)}
              role="checkbox"
              onClick={() => {
                const newModel = { ...addressModel };
                newModel.data.enableMap = !addressModel.data.enableMap;
                setAddressModel(newModel);
              }}
            />
          </FormInputGroup>
        </FlexBox>
        {addressModel.data.enableMap && (
          <DropDownField
            value={addressModel.data.mapStyle as string}
            possibleValues={Object.keys(MapStyle)}
            fieldLabel={messages.mapStyle.id}
            idPrefix={idPrefix}
            updateValue={handleMapStyleChange}
            className={'vbc-form-label-required'}
          />
        )}
      </StandardForm>
    </Page>
  );
};

export default AddressPage;
