import React from 'react';
import noop from 'lodash/noop';

const OnboardingContext = React.createContext({
  backButtonDisabled: false,
  setBackButtonDisabled: noop,
  nextOnboardingStep: noop,
});

export default OnboardingContext;
