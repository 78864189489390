import React, { useState } from 'react';
import { AuthProvider } from '@vp/digital-auth-lib';
import { LocaleProvider } from '@vp/digital-locale-lib';
import { RuntimeContextProvider, getClientEnvironment } from '@vp/digital-environment-lib';
import { useIdentity } from '@vp/auth-react';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import AppContainer from './AppContainer';
import OnboardingContext from './utils/OnboardingContext';
import Confetti from './ui/Confetti';
import CustomDragLayer from './ui/dnd/CustomDragLayer';
import BookendsHeader from './ui/BookendsHeader';
import { SessionProvider } from './hooks/SessionContext';
import AppContent from './AppContent';
import { AnalyticsProvider } from './hooks/AnalyticsProvider';
import RollbarProvider from './hooks/RollbarProvider';
import CareAgentBookendsHeader from './ui/CareAgentBookendsHeader';
import { AssetStoreProvider } from './hooks/AssetStoreProvider';

import './App.scss';

import { Helmet } from 'react-helmet';

import {
  SwanProvider,
  getRootClassNames,
  SwanHead,
  SWAN_STYLE_KEY_MAP,
  SWAN_BASE_URL_MAP,
} from '@vp/swan';

const styleSheetKeys = [
  SWAN_STYLE_KEY_MAP.core,
  SWAN_STYLE_KEY_MAP.spinner,
  SWAN_STYLE_KEY_MAP.detailsBanner,
  SWAN_STYLE_KEY_MAP.grid,
  SWAN_STYLE_KEY_MAP.icon,
  SWAN_STYLE_KEY_MAP.button,
  SWAN_STYLE_KEY_MAP.utility,
  SWAN_STYLE_KEY_MAP.standardTile,
  SWAN_STYLE_KEY_MAP.selectionSet,
  SWAN_STYLE_KEY_MAP.tabs,
  SWAN_STYLE_KEY_MAP.modalDialog,
  SWAN_STYLE_KEY_MAP.alertBox,
];

export const SwanConfiguration: React.FC<unknown> = ({ children }) => {
  const bodyClassName = getRootClassNames();
  // const htmlClassName = useBrowserClasses();
  return (
    <SwanProvider swanBaseUrl={SWAN_BASE_URL_MAP.default.cdn}>
      {/* <Helmet bodyAttributes={{ class: bodyClassName }} htmlAttributes={{ class: htmlClassName }} /> */}
      <Helmet bodyAttributes={{ class: bodyClassName }} />
      <SwanHead renderStyleContentAsChildren styleSheetKeys={styleSheetKeys} />
      {/* Optionally, if you need, wrap ScreenProvider at this level */}
      {children}
    </SwanProvider>
  );
};

const App: React.FC = () => {
  const [onboardingStep, setOnboardingStep] = useState<number>(0);
  const [backButtonDisabled, setBackButtonDisabled] = useState<boolean>(false);
  const [confetti, setConfetti] = useState<boolean>(false);
  const identity = useIdentity();
  const loggedInAsCareAgent = identity.profile['https://claims.cimpress.io/cimpress_internal'];
  const boom = () => {
    setConfetti(true);
    setTimeout(() => {
      setConfetti(false);
    }, 500);
  };

  const nextOnboardingStep = () => {
    setOnboardingStep(onboardingStep + 1);
  };

  return (
    <RollbarProvider>
      <LocaleProvider rawLocaleIdentifier={window.csbData.language}>
        <RuntimeContextProvider getEnvironment={getClientEnvironment}>
          <AuthProvider directAccessClientId={window.vbcConfig.auth0ClientId}>
            <SwanConfiguration>
              <SessionProvider>
                <OnboardingContext.Provider
                  value={{ setBackButtonDisabled, backButtonDisabled, nextOnboardingStep }}
                >
                  <AnalyticsProvider>
                    <AssetStoreProvider>
                      <Confetti enable={confetti} />
                      {loggedInAsCareAgent && <CareAgentBookendsHeader />}
                      <BookendsHeader boom={boom} />
                      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
                        <CustomDragLayer />
                        <AppContainer>
                          <AppContent boom={boom} />
                        </AppContainer>
                      </DndProvider>
                    </AssetStoreProvider>
                  </AnalyticsProvider>
                </OnboardingContext.Provider>
              </SessionProvider>
            </SwanConfiguration>
          </AuthProvider>
        </RuntimeContextProvider>
      </LocaleProvider>
    </RollbarProvider>
  );
};

export default App;
