import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';
import { Button } from '@vp/swan';
import {
  BasicResponsiveImage,
  Link,
  DetailsBanner,
  DetailsBannerImage,
  DetailsBannerText,
  Box,
  Typography,
} from '@vp/swan';
import './WelcomeDialog.scss';

const messages = defineMessages({
  header: 'Create your landing page',
  text: 'This is where you’ll customize the experience your users will see once they access your online landing page.',
  confirmButton: 'Learn how to get started',
  skipTourButton: 'Skip tour',
  welcomeDialogImageAlt: 'Example landing pages displayed on mobile phones',
});

const WelcomeDialog: React.FC<TooltipRenderProps> = (props: TooltipRenderProps) => {
  const { primaryProps, tooltipProps, skipProps } = props;

  const { t } = useTranslation();

  return (
    <DetailsBanner className="welcome-dialog" {...tooltipProps}>
      {!window.isMobile && (
        <DetailsBannerImage>
          <BasicResponsiveImage
            src={`${window.vbcConfig.host}/images/welcomeDialog.png`}
            alt={t(messages.welcomeDialogImageAlt.id)}
            aspectRatio={1}
          />
        </DetailsBannerImage>
      )}
      <DetailsBannerText paddingX={7}>
        <Typography component="h2">{t(messages.header.id)}</Typography>
        <Typography component="p">{t(messages.text.id)}</Typography>
        <Box className="welcome-dialog__buttons" marginTop={7}>
          <Button skin="primary" {...primaryProps} marginRight={4}>
            {t(messages.confirmButton.id)}
          </Button>
          <Link
            className="welcome-dialog__skip-tour-button"
            paddingY={4}
            component="button"
            {...skipProps}
          >
            <Typography fontSize={-1}>{t(messages.skipTourButton.id)}</Typography>
          </Link>
        </Box>
      </DetailsBannerText>
    </DetailsBanner>
  );
};

export default WelcomeDialog;
