import { BusinessHourModel } from '../../../models/OperationHoursModel';
import { isWithinInterval, parse, toDate } from 'date-fns';
import { FormDataModel } from '../../utils/FormUtils';
import TimePicker from 'rc-time-picker-date-fns';
import React from 'react';

const formatStr = 'h:mm a';
const today = new Date();

export interface BusinessHourState {
  openingHour: Date;
  closingHour: Date;
}

export type FormHourModel = {
  data: FormDataModel<BusinessHourState>;
  errors: {
    invalidInterval: string;
    overlappingHours: string;
  };
};

export function makeTimePickerField(
  state: FormHourModel,
  key: keyof BusinessHourModel,
  updateHour: (value: Date, key: keyof BusinessHourModel) => void,
  timePickerClasses: string,
  hoursType: string
): JSX.Element {
  return (
    <label aria-label={hoursType}>
      <TimePicker
        style={{ paddingRight: 5, width: '100%' }}
        className={timePickerClasses}
        value={state.data[key]}
        defaultOpenValue={state.data[key]}
        showSecond={false}
        onChange={(value: Date) => updateHour(value, key)}
        format={formatStr}
        allowEmpty={false}
        minuteStep={15}
        use12Hours
      />
    </label>
  );
}

export function isOverlapping(
  operationHours: Array<BusinessHourModel>,
  hourIndex: number
): boolean {
  if (operationHours.length === 1) {
    return false;
  }
  const interval: BusinessHourModel = operationHours[hourIndex];
  const openingHour = parse(interval.openingHour, formatStr, today);
  const closingHour = parse(interval.closingHour, formatStr, today);
  const overlappingHourIndex = operationHours
    .filter((val, index: number) => index !== hourIndex)
    .findIndex((value) => {
      const otherInterval: Interval = {
        start: parse(value.openingHour, formatStr, today),
        end: parse(value.closingHour, formatStr, today),
      };
      if (toDate(otherInterval.start).getTime() > toDate(otherInterval.end).getTime()) {
        return false;
      }
      return (
        isWithinInterval(openingHour, otherInterval) || isWithinInterval(closingHour, otherInterval)
      );
    });
  return overlappingHourIndex !== -1;
}
