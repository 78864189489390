import React from 'react';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { TabContent, TabHeader, Tabs, TabsContents, TabsHeaders } from '@vp/swan';
import { VisuallyHidden, Typography } from '@vp/swan';
import ColorPalette from '../color/ColorPalette';
import ThemeBackgroundImage from './ThemeBackgroundImage';
import ThemeBackgroundPattern from './ThemeBackgroundPattern';
import { BackgroundKind, Background } from '../../../../models/ThemesModel';
import { ColorList } from '../../../data/Theme';
import { trackStyleEvent } from '../../../utils/trackingUtils';
import CroppedImage, { CropBox } from '../../../../models/CroppedImageModel';

interface Props {
  background: Background;
  onBackgroundChange: (background: Background) => void;
}

const messages = defineMessages({
  background: 'Background',
  color: 'Color',
  image: 'Image',
  upload: 'Upload',
  colorLabel: 'Background color',
});

const ThemeBackground: React.FC<Props> = ({ background, onBackgroundChange }) => {
  const { t } = useTranslation();

  const [selectedTabId, setSelectedTabId] = React.useState<null | string>(messages.color.id);
  // save the last value on each tab that the user selected, so if they switch back to that
  // tab, we will remember that value
  const [selectedColor, setSelectedColor] = React.useState<string>(
    background.kind === BackgroundKind.color ? background.color : ColorList.white
  );
  const emptyCroppedImage: CroppedImage = {
    url: '',
    cropBox: { x: 0, y: 0, width: 366, height: 320, rotate: 0 },
    mimeType: '',
  };
  const [selectedImageURL, setSelectedImageURL] = React.useState<CroppedImage>(
    background.kind === BackgroundKind.image ? background.image : emptyCroppedImage
  );
  const [selectedPatternURL, setSelectedPatternURL] = React.useState<string>(
    background.kind === BackgroundKind.pattern ? background.patternURL : ''
  );
  const [refreshImageUploadTab, setRefreshImageUploadTab] = React.useState<boolean>(false);

  const handleColorChange = (color: string) => {
    setSelectedColor(color);
    onBackgroundChange({ kind: BackgroundKind.color, color });
    trackStyleEvent('Background', 'Update Color Clicked', BackgroundKind.color.toString());
    //Reset other tabs selection
    setSelectedImageURL({ ...emptyCroppedImage });
    setSelectedPatternURL('');
  };
  const handleImageURLChange = (imageURL: string, mimeType: string) => {
    const newImage = { ...selectedImageURL, url: imageURL, mimeType };
    setSelectedImageURL(newImage);
    onBackgroundChange({ kind: BackgroundKind.image, image: newImage });
    trackStyleEvent('Background', 'Image Uploaded', BackgroundKind.image.toString());

    //Reset other tabs selection
    setSelectedColor(ColorList.white);
    setSelectedPatternURL('');
  };

  const handleImageURLDelete = () => {
    setSelectedImageURL({ ...emptyCroppedImage });
    onBackgroundChange({ kind: BackgroundKind.color, color: ColorList.rhino });
    trackStyleEvent('Background', 'Image Deleted', BackgroundKind.image.toString());

    setSelectedColor(ColorList.rhino);
    setSelectedPatternURL('');
  };

  const handlePatternURLChange = (patternURL: string) => {
    setSelectedPatternURL(patternURL);
    onBackgroundChange({ kind: BackgroundKind.pattern, patternURL });
    trackStyleEvent('Background', 'Image Selected', BackgroundKind.pattern.toString());

    //Reset other tabs selection
    setSelectedColor(ColorList.white);
    setSelectedImageURL({ ...emptyCroppedImage });
  };

  const handleCrop = async (cropBox: CropBox) => {
    const newImage = { ...selectedImageURL, cropBox };
    setSelectedImageURL(newImage);
    onBackgroundChange({ kind: BackgroundKind.image, image: newImage });
  };

  return (
    <Tabs
      selectedTabId={selectedTabId}
      onRequestTabChange={(newSelectedTabId) => {
        setSelectedTabId(newSelectedTabId);
        if (newSelectedTabId === messages.upload.id) {
          //we need to refresh the cropper in order to recalculate the preview size
          setRefreshImageUploadTab(!refreshImageUploadTab);
        }
      }}
    >
      <Typography weight="bold" fontSize={3} marginBottom={2}>
        {t(messages.background.id)}
      </Typography>
      <TabsHeaders>
        <TabHeader tabId={messages.color.id}>
          {t(messages.color.id)}
          <VisuallyHidden>{t(messages.color.id)}</VisuallyHidden>
        </TabHeader>

        <TabHeader tabId={messages.upload.id}>
          {t(messages.upload.id)}
          <VisuallyHidden>{t(messages.upload.id)}</VisuallyHidden>
        </TabHeader>

        <TabHeader tabId={messages.image.id}>
          {t(messages.image.id)}
          <VisuallyHidden>{t(messages.image.id)}</VisuallyHidden>
        </TabHeader>
      </TabsHeaders>

      <TabsContents>
        <TabContent tabId={messages.color.id}>
          <ColorPalette
            key={`color_selection_key__${selectedColor}`}
            color={selectedColor}
            onColorChange={handleColorChange}
            labelText={t(messages.colorLabel.id)}
          />
        </TabContent>
        <TabContent tabId={messages.upload.id}>
          <ThemeBackgroundImage
            key={`image_selection_key__${selectedImageURL}`}
            croppedImage={selectedImageURL}
            onImageURLChange={handleImageURLChange}
            onImageURLDelete={handleImageURLDelete}
            onCrop={handleCrop}
            refresh={refreshImageUploadTab}
          />
        </TabContent>
        <TabContent tabId={messages.image.id}>
          <ThemeBackgroundPattern
            key={`pattern_selection_key__${selectedPatternURL}`}
            patternURL={selectedPatternURL}
            onPatternURLChange={handlePatternURLChange}
          />
        </TabContent>
      </TabsContents>
    </Tabs>
  );
};
export default ThemeBackground;
