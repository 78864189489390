import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import ErrorPage from '../pages/ErrorPage';

const RollbarProvider: React.FC = ({ children }) => {
  if (process.env.NODE_ENV === 'development') {
    return <>{children}</>;
  }

  const rollbarConfig = {
    accessToken: window.vbcConfig.rollbarAccessToken,
    environment: window.vbcConfig.env,
    captureUncaught: true,
    captureUnhandledRejections: true,
  };

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={ErrorPage}>{children}</ErrorBoundary>
    </Provider>
  );
};

export default RollbarProvider;
