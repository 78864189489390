import React, { useRef, useState } from 'react';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import Cropper from './Cropper';
import { CropBox } from '../../../models/CroppedImageModel';
import { Spinner, Button, ControlIcon, FlexBox } from '@vp/swan';
import DropDownMenu, { DropDownMenuItem } from '../DropDownMenu';
import MoreBoldIcon from '../../../images/more-bold.svg';

import './ImageInput.scss';

interface ImageInputProps {
  value: string;
  onChange?: (image: File) => void;
  remove?: (image: string) => void;
  enableCropper?: boolean;
  onCrop?: (cropBox: CropBox) => void;
  cropData?: CropBox;
  acceptGif?: boolean;
  multiple?: boolean;
}

const messages = defineMessages({
  loading: 'Image loading',
  altText: 'User uploaded image',
  delete: 'Delete',
  replace: 'Replace',
  edit: 'Edit/Crop',
  imageInputButton: 'Image upload button',
  error: `Oops! It looks like you uploaded a file format that we do not support. You may only upload files that are under 10 MB and have one of the following extensions: jpg, jpeg, png, gif, tiff, webp or svg.`,
});
export const commonImageFormats = [
  'image/jpg',
  'image/png',
  'image/jpeg',
  'image/web',
  'image/webp',
  'image/tiff',
  'image/webp',
  'image/svg+xml',
];

const IMAGE_PROCESSOR_THMB_LARGE_BASE_URL =
  'https://imageprocessor.digital.vistaprint.com/thumbnail/large';

const ImageInput: React.FC<ImageInputProps> = (props: ImageInputProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [cropperVisible, setCropperVisible] = useState(false);
  const [fileChanged, setFileChanged] = useState(false);
  const [maxFileSizeReached, setMaxFileSizeReached] = useState(false);
  const inputFile = useRef<HTMLInputElement>(null);
  const fileMaxSize = 10000000; //10MB
  const { value, onChange, remove, enableCropper, onCrop, cropData, multiple } = props;

  React.useEffect(() => {
    if (value) {
      setLoading(false);
      if (fileChanged) {
        setCropperVisible(true);
      }
    }
  }, [value]);

  /* On file select (from the pop up) */
  const handleFileChange = async (event: any) => {
    const newFile = event.target.files[0] as File;
    if (newFile) {
      if (newFile.size > fileMaxSize) {
        setMaxFileSizeReached(true);
        setLoading(false);
        return false;
      }
      setMaxFileSizeReached(false);
      setLoading(true);
      remove?.(value);
      const fileList = [...event.target.files];
      for (const newFile1 of fileList) {
        if (!commonImageFormats.includes(newFile1.type) && newFile1.type !== 'image/gif') {
          setMaxFileSizeReached(true);
          setLoading(false);
          return false;
        }
        try {
          await onChange?.(newFile1);
        } catch (error) {
          setLoading(false);
        }
      }
      setFileChanged(true);
    }
  };

  const closeCropper = () => {
    setCropperVisible(false);
  };

  const dropDownMenuItems: Array<DropDownMenuItem> = [
    {
      label: t(messages.delete.id),
      action: () => remove?.(value),
    },
    {
      label: t(messages.replace.id),
      action: () => inputFile.current?.click(),
    },
    {
      label: t(messages.edit.id),
      action: () => setCropperVisible(true),
    },
  ];
  const [dropDownOpened, setDropDownOpened] = useState(false);
  const acceptedFormat = `${commonImageFormats.join(',')},${props.acceptGif ? ',image/gif' : ''}`;

  const imgProcessorSrcUrl =
    !value || value?.startsWith(IMAGE_PROCESSOR_THMB_LARGE_BASE_URL)
      ? value
      : `${IMAGE_PROCESSOR_THMB_LARGE_BASE_URL}/${value}`;

  return (
    <div>
      <FlexBox>
        <div className="image-upload">
          <input
            style={{ display: 'none' }}
            type="file"
            onChange={handleFileChange}
            ref={inputFile}
            accept={acceptedFormat}
            multiple={multiple}
          />
          {!isEmpty(value) ? (
            <React.Fragment>
              <img
                className="image-upload__img"
                src={imgProcessorSrcUrl}
                alt={t(messages.altText.id)}
              />
              {enableCropper && <div className="cropperjs-preview" />}
              {!enableCropper && (
                <div
                  className="image-upload__remove"
                  onClick={() => props.remove && props.remove(props.value)}
                >
                  ✕
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className="image-upload__img">
              {loading && (
                <div className="image-upload__preloader">
                  <Spinner accessibleText={t(messages.loading.id)} />
                </div>
              )}
              {!loading && (
                <button
                  className="image-upload__button"
                  role="button"
                  aria-label={t(messages.imageInputButton.id)}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    inputFile.current?.click();
                    e.preventDefault();
                  }}
                >
                  <Button
                    component="div"
                    className="image-upload__plus"
                    buttonShape="round"
                    sizeVariant="mini"
                    role="button"
                    aria-label={t(messages.imageInputButton.id)}
                  >
                    <ControlIcon iconType="plus" />
                  </Button>
                </button>
              )}
            </div>
          )}
        </div>
        {enableCropper && !isEmpty(value) && (
          <>
            <DropDownMenu
              items={dropDownMenuItems}
              open={dropDownOpened}
              setOpen={setDropDownOpened}
              left={-4}
            >
              <Button
                component="div"
                className="image-upload__actions-button"
                buttonShape="round"
                onClick={() => setDropDownOpened(true)}
              >
                <MoreBoldIcon className="image-upload__actions-icon" />
              </Button>
            </DropDownMenu>
            <Cropper
              src={value}
              onCrop={onCrop}
              cropData={cropData}
              visible={cropperVisible}
              close={closeCropper}
            />
          </>
        )}
      </FlexBox>
      {maxFileSizeReached && <p className="input-group-error-text">{t(messages.error.id)}</p>}
    </div>
  );
};

export default ImageInput;
