import React, { useContext } from 'react';
import LinkButton from './LinkButton';
import { Button } from '@vp/swan';
import Pages from '../data/Pages';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';
import RouterContext from '../utils/RouterContext';
import OnboardingContext from '../utils/OnboardingContext';

import './ButtonHeader.scss';

interface ButtonHeaderProps {
  handleSubmit: () => void | Promise<void>;
  enableSubmit: () => boolean;
  isNewBlock?: boolean;
}

const messages = defineMessages({
  add: 'Add Content',
  update: 'Update',
  continueToCheckout: 'Continue To Checkout',
});

const ButtonHeader: React.FC<ButtonHeaderProps> = (props: ButtonHeaderProps) => {
  const { backButtonDisabled } = useContext(OnboardingContext);
  const { changePage } = useContext(RouterContext);
  const { t } = useTranslation();

  return (
    <div className="button-header">
      <div className={`button-header-link-button ${backButtonDisabled ? 'disabled' : ''}`}>
        <LinkButton to={Pages.Main} iconType="chevronLeft" />
      </div>
      <div>
        <Button
          skin="primary"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            Promise.resolve(props.handleSubmit())
              .then(() => {
                changePage(Pages.Main);
              })
              // stay on Page when an error happens. Errors should be shown in page
              .catch();
          }}
          disabled={props.enableSubmit ? props.enableSubmit() === false : false}
        >
          {props.isNewBlock ? t(messages.add.id) : t(messages.update.id)}
        </Button>
      </div>
    </div>
  );
};

export default ButtonHeader;
