export enum ColorList {
  white = 'ffffff',
  rhino = '25384F',
  tangaroa = '10253F',
  pacificblue = '00B5C6',
  zinnwaldite = 'ECACAC',
  alto = 'DFDFDF',
  gray = '909090',
  black = '000000',
}
