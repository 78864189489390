import React, { createContext } from 'react';
import { VistaAssetStore, SubBrands, Tenants } from '@design-stack-vista/vista-assets-sdk';
import { useIdentity } from '@vp/auth-react';

interface AssetStoreContextModel {
  assetStore: any;
  uploadAsset: (file: File) => Promise<string>;
}

const AssetStoreContext = createContext<AssetStoreContextModel>({} as AssetStoreContextModel);

const AssetStoreProvider: React.FC = ({ children }) => {
  const identity = useIdentity();

  const authProvider = {
    getAuthHeaders: async () => ({
      Authorization: `Bearer ${identity.accessToken}`,
    }),
  };

  const uploadAsset = async (file: File): Promise<string> => {
    const asset = await assetStore.upload({ file, expires: 'never' });
    await asset.presign();
    const publicUrl = asset.getUrl({ includeSignature: true });
    return publicUrl;
  };

  const assetStore = new VistaAssetStore({
    authProvider,
    subBrand: SubBrands.VistaPrint,
    experience: 'ConnectedProductsSiteBuilder',
    writeTenant: Tenants.VistaPrint,
  });

  return (
    <AssetStoreContext.Provider value={{ assetStore, uploadAsset }}>
      {children}
    </AssetStoreContext.Provider>
  );
};

export { AssetStoreProvider, AssetStoreContext };
