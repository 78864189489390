import axios from 'axios';
const siteFlowApiRoute = `${window.vbcConfig.siteFlow}`;

const getNextStepUrl = async (currentStep: string, locale: string): Promise<string> => {
  let response;
  try {
    const url = `${siteFlowApiRoute}/v2/nextstep/vistaprint/${locale}/${currentStep}/connectedBusinessCards?requestor=${window.vbcConfig.requestor}`;
    response = await axios.get(url);
  } catch (error) {
    const message = `Error getting next step for current step ${currentStep} Error is ${error}`;
    throw new Error(message);
  }
  return response.data.url;
};

export { getNextStepUrl };
