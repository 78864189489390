import React from 'react';
import SiteModel, { ButtonModels, PageModel, SiteModelKeys } from '../../../models/SiteModel';
import Pages from '../../data/Pages';
import LinkCard from '../LinkCard';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';

const messages = defineMessages({
  aboutModel: 'About',
  callNowModel: 'Call Now',
  contactsModel: 'Add to Contacts',
  customLinkModel: 'Custom link',
  emailUsModel: 'Email Us',
  galleryModel: 'Photo Gallery',
  operationHoursModel: 'Hours',
  socialLinksModel: 'Social',
  videoModel: 'Video',
  websiteModel: 'Website',
  addressModel: 'Address',
  themesModel: 'Themes',
  uploadPDFModel: 'Upload PDF',
  aboutModelHint: 'Displays information about you and/or your business',
  callNowModelHint: 'Adds a button for visitors to quickly call you',
  contactsModelHint: 'Adds a button for visitors to quickly download your contact information',
  customLinkModelHint: 'Creates a custom link to any site you want. Add unlimited custom links',
  emailUsModelHint: 'Adds a button for visitors to quickly send you an email',
  galleryModelHint: 'Displays images that you upload',
  operationHoursModelHint: 'Displays your hours of operation',
  socialLinksModelHint: 'Displays links to your social media channels',
  videoModelHint: 'Displays a video from YouTube or Vimeo',
  websiteModelHint: 'Creates a link to your website',
  addressModelHint: 'Displays your address',
  uploadPDFModelHint: 'Opens a PDF - good for restaurant menus, services menus, pamphlets etc.',
});

interface Props {
  siteModel: SiteModel;
  handleDelete: (index: number) => void;
  handleReorder: (indexA: number, indexB: number) => void;
  confirmReorder: () => void;
  translate: (key: string) => string;
}

const Features: React.FC<Props> = (props) => {
  function makeButton(
    key: SiteModelKeys,
    page: Pages,
    hasData: boolean,
    index = -1,
    options?: {
      extraText?: string;
      id?: string;
    }
  ): JSX.Element {
    const text = options?.extraText ? options.extraText : props.translate(messages[key].id);
    const hint = props.translate(messages[`${key}Hint`]?.id);
    return (
      <LinkCard
        to={{ page, id: options?.id }}
        text={text}
        hint={hint}
        key={key + index}
        index={index}
        handleReorder={props.handleReorder}
        confirmReorder={props.confirmReorder}
        onDelete={() => props.handleDelete(index)}
        hasData={hasData}
      />
    );
  }

  const usedButtons: JSX.Element[] = [];

  // separate the buttons for pages with data from the ones without
  props.siteModel.forEach((block: PageModel, index) => {
    if (block.key === 'themesModel') {
      return;
    }

    const data = block.data;

    if ((block.key === 'customLinkModel' || block.key === 'socialLinksModel') && data) {
      usedButtons.push(
        makeButton(block.key, ButtonModels[block.key], true, index, {
          extraText: (data as any).label as string,
          id: (data as any).id as string,
        })
      );
    } else {
      usedButtons.push(
        makeButton(block.key, ButtonModels[block.key], true, index, {
          extraText: (data as any).label as string,
        })
      );
    }
  });

  const unusedButtons: JSX.Element[] = [];

  // separate the buttons for pages with data from the ones without
  forEach(ButtonModels, (path: Pages, key: string) => {
    const castedKey = key as SiteModelKeys;
    if (
      castedKey !== 'themesModel' &&
      (castedKey === 'customLinkModel' ||
        castedKey === 'socialLinksModel' ||
        !find(props.siteModel, { key: castedKey }))
    ) {
      unusedButtons.push(makeButton(castedKey, ButtonModels[castedKey], false));
    }
  });

  return (
    <>
      {usedButtons.length ? (
        <section className="editor-section used-cards-section">{usedButtons}</section>
      ) : null}
      {unusedButtons.length ? (
        <section className="editor-section unused-card-section">{unusedButtons}</section>
      ) : null}
    </>
  );
};
export default Features;
