enum Pages {
  Main = '/',
  Contacts = 'Contacts',
  Theme = 'Theme',
  CallNow = 'CallNow',
  About = 'About',
  Website = 'Website',
  CustomLink = 'CustomLink',
  Hours = 'Hours',
  Video = 'Video',
  Social = 'SocialLinks',
  Gallery = 'Gallery',
  UploadPDF = 'UploadPDF',
  Address = 'Address',
  EmailUs = 'EmailUs',
}
export default Pages;
