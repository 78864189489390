import React, { createContext, useEffect, useState } from 'react';
import { VistaprintAuth } from '@vp/auth-react';
import { Spinner } from '@vp/swan';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';
import { fetchUserByConnectedProduct } from '../../api';
import UserModel from '../../models/UserModel';
import SiteModel from '../../models/SiteModel';
import { Work } from '../../models/WorkModel';
import ErrorPage from '../pages/ErrorPage';
import vpAccessTokenKeys from '../../../common/vpAccessTokenKeys';

const messages = defineMessages({
  loading: 'Loading',
});
interface SessionContextModel {
  userData: UserModel;
  siteData: SiteModel;
  tenant?: string; // TODO VBC-828 remove
  work?: Work | null; // TODO VBC-828 remove
  setSiteData: (siteData: SiteModel) => void;
}

const SessionContext = createContext<SessionContextModel>({} as SessionContextModel);

const SessionProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState<UserModel | null>(null);
  const [siteData, setSiteData] = useState<SiteModel | null>(null);
  const [statusError, setStatusError] = useState<number | null>(null);
  const auth = new (VistaprintAuth.WebAuth as any)();
  const loggedInAsCareAgent = auth.getIdentity().profile[vpAccessTokenKeys.isInternal];

  useEffect(() => {
    const init = async () => {
      const params = new URLSearchParams(document.location.search.substring(1));
      const connectedProductAltId = params.get('altId');
      const workId = params.get('workId');

      if (!connectedProductAltId && !workId) {
        alert(`Either AltId or WorkId should be specified`);
      }

      let user;
      try {
        user = await fetchUserByConnectedProduct({
          connectedProductAltId: connectedProductAltId,
          workId: workId,
        });
      } catch (e) {
        setStatusError(404);
        return;
      }

      // TODO VBC-680 better error msgs for these error cases
      if (!user) {
        setStatusError(403);
        return;
      }
      if (!user && loggedInAsCareAgent) {
        setStatusError(409);
        return;
      }
      setUserData(user);
      setSiteData(user.connectedProduct.siteModel);
    };
    init();
  }, []);

  if (statusError) {
    return <ErrorPage errorCode={statusError} />;
  }

  return siteData && userData ? (
    <SessionContext.Provider value={{ userData, siteData, setSiteData }}>
      {children}
    </SessionContext.Provider>
  ) : (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner accessibleText={t(messages.loading.id)} size="super" />
    </div>
  );
};

export { SessionContext, SessionProvider };
