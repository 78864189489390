import React from 'react';
import { Typography } from '@vp/swan';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { ButtonDesign, ButtonKind } from '../../../../models/ThemesModel';
import ColorPalette from '../color/ColorPalette';
import ButtonShape from './ButtonShape';
import { trackStyleEvent } from '../../../utils/trackingUtils';

interface Props {
  buttonDesign: ButtonDesign;
  t: (key: string) => string;
  onButtonDesignChange: (buttonDesign: ButtonDesign) => void;
}

const messages = defineMessages({
  buttons: 'Buttons',
  buttonOutlineColor: 'Button outline/text color',
  buttonColor: 'Button color',
  textColor: 'Button text color',
  square: 'Square',
  rounded: 'Rounded',
  pill: 'Pill',
  squareOutline: 'Square outline',
  roundedOutline: 'Rounded outline',
  pillOutline: 'Pill outline',
  squareDescription: 'Square button',
  roundedDescription: 'Soft rounded button',
  pillDescription: 'Pill rounded button',
  squareOutlineDescription: 'Button with square outline',
  roundedOutlineDescription: 'Button with soft rounded outline',
  pillOutlineDescription: 'Button with pill outline',
});

const ThemeButtons: React.FC<Props> = ({ buttonDesign, t, onButtonDesignChange }) => {
  const handleButtonShapeChange = (shape: ButtonKind) => {
    const newModel: ButtonDesign = { ...buttonDesign, shape };
    trackStyleEvent('Buttons', shape + ' Selected');
    onButtonDesignChange(newModel);
  };

  const handleButtonColorChange = (color: string) => {
    const newModel: ButtonDesign = { ...buttonDesign, color };
    trackStyleEvent('Buttons', 'Update Color Clicked', 'Color');
    onButtonDesignChange(newModel);
  };

  const handleTextColorChange = (textColor: string) => {
    const newModel: ButtonDesign = { ...buttonDesign, textColor };
    trackStyleEvent('Buttons', 'Update Text Color Clicked', 'Text');
    onButtonDesignChange(newModel);
  };

  const isOutline = buttonDesign.shape.toLowerCase().includes('outline');
  const buttonColorText = isOutline
    ? t(messages.buttonOutlineColor.id)
    : t(messages.buttonColor.id);

  return (
    <>
      <Typography weight="bold" fontSize={3} marginTop={1}>
        {t(messages.buttons.id)}
      </Typography>
      <div className="styleToggle-container">
        <ButtonShape
          shape={buttonDesign.shape}
          t={t}
          onButtonShapeChange={handleButtonShapeChange}
        />
        <ColorPalette
          color={buttonDesign.color}
          onColorChange={handleButtonColorChange}
          labelText={buttonColorText}
        />
        {!isOutline && (
          <ColorPalette
            color={buttonDesign.textColor}
            onColorChange={handleTextColorChange}
            labelText={t(messages.textColor.id)}
          />
        )}
      </div>
    </>
  );
};
export default ThemeButtons;
