import { FormDataModel, FormSchema } from '../components/utils/FormUtils';
import { isEmail, isPhone, isWebsite } from '../components/utils/ValidationUtils';

type ContactsModel = FormDataModel<{
  label: string;
  firstName: string;
  lastName: string;
  role: string;
  companyName: string;
  phone: string;
  email: string;
  website: string;
}>;

export const contactsSchema: FormSchema<ContactsModel> = {
  label: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  firstName: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  lastName: {
    type: 'text',
    required: false,
    validator: null,
    validatorError: null,
  },
  role: {
    type: 'text',
    required: false,
    validator: null,
    validatorError: null,
  },
  companyName: {
    type: 'text',
    required: false,
    validator: null,
    validatorError: null,
  },
  phone: {
    type: 'phone',
    required: false,
    validator: isPhone,
    validatorError: 'client.components.utils.ValidationUtils.phone',
  },
  email: {
    type: 'text',
    required: false,
    validator: isEmail,
    validatorError: 'client.components.utils.ValidationUtils.email',
  },
  website: {
    type: 'text',
    required: false,
    validator: isWebsite,
    validatorError: 'client.components.utils.ValidationUtils.website',
  },
};

export default ContactsModel;
