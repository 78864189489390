import React from 'react';
import { AlertBox, AlertBoxDismissButton, Link } from '@vp/swan';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';

const messages = defineMessages({
  error: 'Oops, we’re having trouble loading your page.',
  retry: 'Try Again',
  dismiss: 'Dismiss alert',
});

const PreviewError: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div style={{ zIndex: 1 }}>
      <AlertBox skin="error" m={4} style={{ zIndex: 1 }}>
        {t(messages.error.id)}{' '}
        <Link component="button" onClick={() => window.location.reload()}>
          {t(messages.retry.id)}
        </Link>
        <AlertBoxDismissButton visuallyHiddenLabel={t(messages.dismiss.id)} />
      </AlertBox>
    </div>
  );
};
export default PreviewError;
