import React, { useState } from 'react';
import { makeSimpleForm, isValidForm, FormStateModel } from '../utils/FormUtils';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import VideoModel, { videoSchema } from '../../models/VideoModel';
import PageBaseProps from './PageBase';
import Page from '../ui/Page';
import isEmpty from 'lodash/isEmpty';

const messages = defineMessages({
  title: 'Video',
  desc: 'Add your youtube or Vimeo URL.',
  url: 'Youtube or Vimeo URL',
  label: 'What do you want to name this section?',
  videoPlaceHolder: 'e.g. https://www.youtube.com/watch?v=ID',
});

const VideoPage: React.FC<PageBaseProps<VideoModel>> = (props) => {
  const { t } = useTranslation();
  const initialData = props.formData || {
    label: t(messages.title.id).toString(),
    url: '',
  };
  const [video, setVideo] = useState<FormStateModel<VideoModel>>({
    data: initialData,
    errors: {
      label: null,
      url: null,
    },
    dirty: {
      label: !isEmpty(initialData.label),
      url: !isEmpty(initialData.url),
    },
  });

  const handleSubmit = () => {
    props.onSubmit({ key: 'videoModel', data: video.data });
  };
  const enableSubmit = (): boolean => {
    return isValidForm<VideoModel>(video.data, videoSchema);
  };

  return (
    <Page
      title={t(messages.title.id)}
      desc={t(messages.desc.id)}
      enableSubmit={enableSubmit}
      handleSubmit={handleSubmit}
      isNewBlock={!props.formData}
    >
      {makeSimpleForm(video, videoSchema, t, 'client.components.pages.VideoPage', setVideo)}
    </Page>
  );
};

export default VideoPage;
