import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption, FormField, FormInputGroup, FormLabel } from '@vp/swan';

interface DropDownProps {
  value: string;
  possibleValues: string[];
  placeholder?: string;
  fieldLabel: string;
  idPrefix: string;
  updateValue: (value: string) => void;
  className?: string;
}
const DropDownField: React.FC<DropDownProps> = ({
  value,
  possibleValues,
  placeholder,
  fieldLabel,
  idPrefix,
  updateValue,
  className,
}) => {
  const { t } = useTranslation();

  const options = possibleValues.map((option, index) => (
    <DropdownOption key={index} value={option}>
      {t(`${idPrefix}.${option}`)}
    </DropdownOption>
  ));

  const dropdown = (
    <Dropdown
      fullWidth
      value={value}
      className={className}
      onChange={(event: React.FormEvent<HTMLSelectElement>) => {
        const newValue = event.currentTarget.value;
        updateValue(newValue);
      }}
    >
      {placeholder && (
        <DropdownOption hidden disabled>
          {t(placeholder)}
        </DropdownOption>
      )}
      {options}
    </Dropdown>
  );

  return (
    <FormField>
      <FormLabel> {t(fieldLabel)}</FormLabel>
      <FormInputGroup>{dropdown}</FormInputGroup>
    </FormField>
  );
};
export default DropDownField;
