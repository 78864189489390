import { FormDataModel, FormSchema } from '../components/utils/FormUtils';

type SocialLinksModel = FormDataModel<{
  platform: string;
  label: string;
  link: string;
  id: string;
  url: string;
}>;

export const socialLinksSchema: FormSchema<SocialLinksModel> = {
  platform: {
    type: 'ignore',
    required: true,
    validator: null,
    validatorError: null,
  },
  label: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  link: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  id: {
    type: 'ignore',
    required: true,
    validator: null,
    validatorError: null,
  },
  url: {
    type: 'ignore',
    required: false,
    validator: null,
    validatorError: null,
  },
};

export default SocialLinksModel;
