import React, { useEffect } from 'react';
import Popup from 'reactjs-popup';
import { DropDownMenuItem } from './DropDownMenuItem';

import './DropDownMenu.scss';

interface DropDownMenuProps {
  items: Array<DropDownMenuItem>;
  open: boolean;
  setOpen: (b: boolean) => void;
  left?: number;
}

const DropDownMenu: React.FC<DropDownMenuProps> = (
  props: React.PropsWithChildren<DropDownMenuProps>
) => {
  useEffect(() => {
    const handleScroll = () => {
      props.setOpen(false);
    };

    document.getElementById('.editor-container')?.addEventListener('scroll', handleScroll);

    return () =>
      document.getElementById('.editor-container')?.removeEventListener('scroll', handleScroll);
  }, []);

  const itemList = props.items.map((item: DropDownMenuItem, i: number) => {
    const key = `dropdown-item-${i}`;

    if (item === 'divider') {
      return <div className="dropdown-divider" key={key} />;
    }
    return (
      <a className="dropdown-item" onClick={item.action} key={key}>
        {item.label}
      </a>
    );
  });

  const left = `${props.left}rem`;

  return (
    <Popup
      className="dropdown-container"
      open={props.open}
      on={['click']}
      trigger={() => <div>{props.children}</div>}
      position="top center"
    >
      <div className="dropdown-menu" style={{ left: left }}>
        {itemList}
      </div>
    </Popup>
  );
};

export default DropDownMenu;
