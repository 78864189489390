import { FormDataModel, FormSchema } from '../components/utils/FormUtils';
import CroppedImage from './CroppedImageModel';

type AboutModel = FormDataModel<{
  label: string;
  image: CroppedImage;
  fullName: string;
  companyRole: string;
  bio: string;
}>;

export const aboutSchema: FormSchema<AboutModel> = {
  label: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  image: {
    type: 'croppedImage',
    required: false,
    validator: null,
    validatorError: null,
  },
  fullName: {
    type: 'text',
    required: false,
    validator: null,
    validatorError: null,
  },
  companyRole: {
    type: 'text',
    required: false,
    validator: null,
    validatorError: null,
  },
  bio: {
    type: 'paragraph',
    required: false,
    validator: null,
    validatorError: null,
  },
};

export default AboutModel;
