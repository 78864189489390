import { FormDataModel, FormSchema } from '../components/utils/FormUtils';
import { isWebsite } from '../components/utils/ValidationUtils';

export type CustomLinkModel = FormDataModel<{
  label: string;
  url: string;
  id: string;
}>;

export const customLinkSchema: FormSchema<CustomLinkModel> = {
  label: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  url: {
    type: 'text',
    required: true,
    validator: isWebsite,
    validatorError: 'client.components.utils.ValidationUtils.website',
  },
  id: {
    type: 'ignore',
    required: true,
    validator: null,
    validatorError: null,
  },
};

export default CustomLinkModel;
