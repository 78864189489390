import React, { useContext, useState } from 'react';
import PageBaseProps from './PageBase';
import GalleryModel, { gallerySchema } from '../../models/GalleryModel';
import Page from '../ui/Page';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';
import { StandardForm, FlexBox } from '@vp/swan';
import ImageInput from '../ui/imageInput';
import { AssetStoreContext } from '../hooks/AssetStoreProvider';
import {
  FormStateModel,
  inputGenerators,
  isValidForm,
  preventEnterSubmission,
} from '../utils/FormUtils';
import isEmpty from 'lodash/isEmpty';

const messages = defineMessages({
  title: 'Photo gallery',
  desc: 'Add photo to display in your photo gallery',
  label: 'What do you want to name this section?',
});

const GalleryPage: React.FC<PageBaseProps<GalleryModel>> = (props) => {
  const { t } = useTranslation();
  const { uploadAsset } = useContext(AssetStoreContext);
  const initialData = props.formData || {
    label: t(messages.title.id).toString(),
    images: [],
  };
  const [gallery, setGallery] = useState<FormStateModel<GalleryModel>>({
    data: initialData,
    errors: {
      label: null,
      images: null,
    },
    dirty: {
      label: !isEmpty(initialData.label),
      images: !isEmpty(initialData.images),
    },
  });

  const handleSubmit = () => {
    props.onSubmit({ key: 'galleryModel', data: gallery.data });
  };

  const enableSubmit = (): boolean => {
    return isValidForm<GalleryModel>(gallery.data, gallerySchema);
  };

  const addImage = async (image: File) => {
    try {
      const imageUrl = await uploadAsset(image);
      if (imageUrl !== null) {
        (gallery.data.images as string[]).push(imageUrl);
        gallery.errors.images = null;
      }
    } catch (e) {
      const msg = 'Error while uploading file' + e.message;
      console.error(msg); // eslint-disable-line no-console
      gallery.errors.images = t('client.components.utils.ValidationUtils.genericError');
      setGallery({ ...gallery });
      throw new Error(msg);
    }
    setGallery({ ...gallery });
  };

  const deleteImage = (image: string) => {
    const index = (gallery.data.images as string[]).indexOf(image);
    if (index > -1) {
      (gallery.data.images as string[]).splice(index, 1);
      setGallery({ ...gallery });
    }
  };

  const idPrefix = `client.components.pages.GalleryPage`;
  const inputFields = Object.keys(gallery.data)
    .filter((key) => gallerySchema[key as keyof GalleryModel].type !== 'ignore')
    .map((key) => {
      const inputType = gallerySchema[key as keyof GalleryModel].type;
      const inputGenerator = inputGenerators[inputType];
      return inputGenerator<GalleryModel>(
        gallery,
        gallerySchema,
        key as keyof GalleryModel,
        t(`${idPrefix}.${key}`),
        '',
        t,
        setGallery
      );
    });

  const makeImageSpan = (
    index: number,
    image: string,
    upload?: (image: File) => void,
    remove?: (image: string) => void
  ) => {
    return (
      <span style={{ margin: 10 }} key={index}>
        <ImageInput value={image} onChange={upload} remove={remove} multiple={true} />
      </span>
    );
  };

  const images = (gallery.data.images as string[]).map((image, index) => {
    return makeImageSpan(index, image, undefined, deleteImage);
  });

  return (
    <Page
      title={t(messages.title.id)}
      desc={t(messages.desc.id)}
      enableSubmit={enableSubmit}
      handleSubmit={handleSubmit}
      isNewBlock={!props.formData}
    >
      <StandardForm className="vbc-form" onKeyPress={preventEnterSubmission}>
        {inputFields}
        <FlexBox flexDirection={'row'} flexWrap={'wrap'}>
          {images}
          {makeImageSpan((gallery.data.images as string[]).length + 1, '', addImage)}
        </FlexBox>
        {gallery.errors.images && <p className="input-group-error-text">{gallery.errors.images}</p>}
      </StandardForm>
    </Page>
  );
};
export default GalleryPage;
