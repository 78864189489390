import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexBox, Checkbox } from '@vp/swan';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { BusinessHourModel, OperationDayModel } from '../../../models/OperationHoursModel';
import BusinessHour from './BusinessHour';

import './OperationDay.scss';

interface Props {
  businessHours: OperationDayModel;
  dayIndex: number;
  addNewHours: (index: number) => void;
  removeHours: (dayIndex: number, hourIndex: number) => void;
  toggleDayOpen: (index: number) => void;
  updateHourValue: (
    dayIndex: number,
    hourIndex: number,
    key: keyof BusinessHourModel,
    newValue: string
  ) => void;
  setValidDay: (dayIndex: number, hoursIndex: number, isValid: boolean) => void;
}

const messages = defineMessages({
  '0': 'Monday',
  '1': 'Tuesday',
  '2': 'Wednesday',
  '3': 'Thursday',
  '4': 'Friday',
  '5': 'Saturday',
  '6': 'Sunday',
  addHours: 'Add Hours',
  closed: 'Closed',
});

const OperationDay: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  let hourComponents: Array<JSX.Element> = [];

  if (props.businessHours.open) {
    hourComponents = props.businessHours.hours.map((hours, index: number) => (
      <BusinessHour
        key={`${hours.openingHour}-${hours.closingHour}-${index}`}
        interval={hours}
        removeHours={props.removeHours.bind(null, props.dayIndex, index)}
        operationHours={props.businessHours.hours}
        hoursIndex={index}
        updateHourValue={props.updateHourValue.bind(null, props.dayIndex, index)}
        setValidHour={props.setValidDay.bind(null, props.dayIndex, index)}
      />
    ));
  }

  const addHoursElement = props.businessHours.open ? (
    <div className="operation-day-add-hours" onClick={() => props.addNewHours(props.dayIndex)}>
      {t(messages.addHours.id)}
    </div>
  ) : (
    <div className="operation-day-closed">{t(messages.closed.id)}</div>
  );

  return (
    <div style={{ margin: '20px 0 0 0' }}>
      <FlexBox>
        <Checkbox
          aria-required="false"
          aria-label={t(messages[`${props.dayIndex}`].id)}
          role="checkbox"
          aria-checked={!props.businessHours.open}
          onChange={() => props.toggleDayOpen(props.dayIndex)}
        />
        <div className="operation-day-label">{t(messages[`${props.dayIndex}`].id)}</div>
        {addHoursElement}
      </FlexBox>
      <div>{hourComponents}</div>
    </div>
  );
};

export default OperationDay;
