import React, { useEffect } from 'react';
import { format, parse, isBefore } from 'date-fns';
import { BusinessHourModel } from '../../../models/OperationHoursModel';
import { Box, FormField, FormInputGroup, FormError } from '@vp/swan';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { FormHourModel, isOverlapping, makeTimePickerField } from './HoursUtils';
import cloneDeep from 'lodash/cloneDeep';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';
import TrashIcon from '../../../images/trash.svg';

import './BusinessHour.scss';
import 'rc-time-picker-date-fns/assets/index.css';

interface Props {
  interval: BusinessHourModel;
  removeHours: () => void;
  operationHours: Array<BusinessHourModel>;
  hoursIndex: number;
  updateHourValue: (key: keyof BusinessHourModel, newValue: string) => void;
  setValidHour: (isValid: boolean) => void;
}

const formatStr = 'h:mm a';
const today = new Date();

const messages = defineMessages({
  invalidInterval: 'Opening time cannot be after closing time',
  overlappingHours: 'Business hours are overlapping',
  openingHour: 'Opening Hour TimePicker',
  closingHour: 'Closing Hour TimePicker',
});

const BusinessHour: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { interval, removeHours, operationHours, hoursIndex, updateHourValue } = props;
  const initialData = {
    openingHour: interval.openingHour
      ? parse(interval.openingHour, formatStr, today)
      : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9, 0, 0),
    closingHour: interval.closingHour
      ? parse(interval.closingHour, formatStr, today)
      : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 18, 0, 0),
  };
  const [state, setSate] = React.useState<FormHourModel>({
    data: initialData,
    errors: {
      invalidInterval: '',
      overlappingHours: '',
    },
  });
  const timePickerClasses = classNames('business-hour-time-picker', {
    'is-invalid': !isEmpty(state.errors.invalidInterval) || !isEmpty(state.errors.overlappingHours),
  });

  useEffect(() => {
    const newState = cloneDeep(state);
    newState.errors.overlappingHours = isOverlapping(operationHours, hoursIndex)
      ? t(messages.overlappingHours.id)
      : '';
    setSate(newState);
    props.setValidHour(
      isEmpty(newState.errors.overlappingHours) && isEmpty(newState.errors.invalidInterval)
    );
  }, [JSON.stringify(operationHours)]);

  function validateHour(formState: FormHourModel): FormHourModel {
    const newFormErrors = { ...formState.errors };
    newFormErrors.invalidInterval = isBefore(
      formState.data.openingHour as Date,
      formState.data.closingHour as Date
    )
      ? ''
      : t(messages.invalidInterval.id);
    return { data: formState.data, errors: newFormErrors };
  }

  function updateHour(value: Date, key: keyof BusinessHourModel) {
    const formattedTime = value ? format(value, formatStr) : '';
    updateHourValue(key, formattedTime);
    let newState = { ...state };
    newState.data[key] = value;
    newState = validateHour(newState);
    setSate(newState);
  }

  return (
    <FormField>
      <FormInputGroup>
        <Box className="business-hour-row">
          {makeTimePickerField(
            state,
            'openingHour',
            updateHour,
            timePickerClasses,
            t(messages.openingHour.id)
          )}
          {makeTimePickerField(
            state,
            'closingHour',
            updateHour,
            timePickerClasses,
            t(messages.closingHour.id)
          )}
          <div className="business-hour-delete-container">
            <TrashIcon className="business-hour-delete" onClick={removeHours} />
          </div>
        </Box>
        {state.errors.invalidInterval && <FormError>{state.errors.invalidInterval}</FormError>}
        {state.errors.overlappingHours && <FormError>{state.errors.overlappingHours}</FormError>}
      </FormInputGroup>
    </FormField>
  );
};
export default BusinessHour;
