import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { TextInput, FormError, StandardForm } from '@vp/swan';
import OperationHoursModel, {
  BusinessHourModel,
  OperationDayModel,
} from '../../models/OperationHoursModel';
import Page from '../ui/Page';
import OperationDay from '../ui/hours/OperationDay';
import PageBaseProps from './PageBase';
import { makeFormFieldWrapper, preventEnterSubmission } from '../utils/FormUtils';

const messages = defineMessages({
  title: 'Hours of operation',
  desc: 'Let your customers know when your business is open.',
  hoursLabel: 'What do you want to name this section?',
});

const defaultDays: OperationDayModel[] = [
  { open: true, hours: [{ openingHour: '09:00 AM', closingHour: '06:00 PM' }] },
  { open: true, hours: [{ openingHour: '09:00 AM', closingHour: '06:00 PM' }] },
  { open: true, hours: [{ openingHour: '09:00 AM', closingHour: '06:00 PM' }] },
  { open: true, hours: [{ openingHour: '09:00 AM', closingHour: '06:00 PM' }] },
  { open: true, hours: [{ openingHour: '09:00 AM', closingHour: '06:00 PM' }] },
  { open: false, hours: [{ openingHour: '09:00 AM', closingHour: '06:00 PM' }] },
  { open: false, hours: [{ openingHour: '09:00 AM', closingHour: '06:00 PM' }] },
];

const HoursPage: React.FC<PageBaseProps<OperationHoursModel>> = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<OperationHoursModel>({
    operationDays: props.formData?.operationDays || defaultDays,
    label: props.formData ? props.formData.label : t(messages.title.id).toString(),
  });
  const [labelError, setLabelError] = useState('');
  const [validDays, setValidDays] = useState<boolean[][]>([
    [true],
    [true],
    [true],
    [true],
    [true],
    [true],
    [true],
  ]);

  const addNewHours = (index: number) => {
    const modifiedState: OperationHoursModel = cloneDeep(state);
    modifiedState.operationDays[index].hours.push({
      openingHour: '09:00 AM',
      closingHour: '06:00 PM',
    });
    setState(modifiedState);
  };

  const removeHours = (dayIndex: number, hourIndex: number) => {
    const modifiedState: OperationHoursModel = cloneDeep(state);
    modifiedState.operationDays[dayIndex].hours.splice(hourIndex, 1);

    const newValidityState = { ...validDays };
    newValidityState[dayIndex].splice(hourIndex, 1);
    setValidDays(newValidityState);

    // A day with no hours is automatically closed
    if (modifiedState.operationDays[dayIndex].hours.length === 0) {
      modifiedState.operationDays[dayIndex].open = false;
    }
    setState(modifiedState);
  };

  const toggleDayOpen = (index: number) => {
    const modifiedState: OperationHoursModel = cloneDeep(state);
    modifiedState.operationDays[index].open = !modifiedState.operationDays[index].open;
    if (
      modifiedState.operationDays[index].open &&
      modifiedState.operationDays[index].hours.length === 0
    ) {
      modifiedState.operationDays[index].hours.push({
        openingHour: '09:00 AM',
        closingHour: '06:00 PM',
      });
    }
    setState(modifiedState);
  };

  const updateHourValue = (
    dayIndex: number,
    hourIndex: number,
    key: keyof BusinessHourModel,
    newValue: string
  ) => {
    const modifiedState: Array<OperationDayModel> = state.operationDays;
    // @TODO investigate this
    modifiedState[dayIndex].hours[hourIndex][key] = newValue;
    setState({ operationDays: modifiedState, label: state.label });
  };

  const setValidDay = (dayIndex: number, hoursIndex: number, isValid: boolean) => {
    const newValidState = { ...validDays };
    validDays[dayIndex].splice(hoursIndex, 1, isValid);
    newValidState[dayIndex] = validDays[dayIndex];
    setValidDays(newValidState);
  };

  const initialDays = (state.operationDays as Array<OperationDayModel>).map(
    (d: OperationDayModel, index: number) => (
      <OperationDay
        key={`operationDay_${index}`}
        businessHours={d}
        dayIndex={index}
        addNewHours={addNewHours}
        removeHours={removeHours}
        toggleDayOpen={toggleDayOpen}
        updateHourValue={updateHourValue}
        setValidDay={setValidDay}
      />
    )
  );

  const handleSubmit = () => {
    props.onSubmit({
      key: 'operationHoursModel',
      data: { operationDays: state.operationDays, label: state.label },
    });
  };

  const labelJSX = (
    <>
      <TextInput
        value={state.label as string}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          const newLabel = e.currentTarget.value;
          setLabelError(
            isEmpty(newLabel) ? 'client.components.utils.ValidationUtils.required' : ''
          );
          setState({ ...state, label: newLabel });
        }}
      />
      {!isEmpty(labelError) && <FormError>{t(labelError)}</FormError>}
    </>
  );

  function isHoursModelValid() {
    const areHoursValid = Object.values(validDays).every(function (arr) {
      return arr.every(Boolean);
    });
    return !isEmpty(state.label) && areHoursValid;
  }

  return (
    <Page
      title={t(messages.title.id)}
      desc={t(messages.desc.id)}
      enableSubmit={isHoursModelValid}
      handleSubmit={handleSubmit}
      isNewBlock={!props.formData}
    >
      <StandardForm className="vbc-form" onKeyPress={preventEnterSubmission}>
        {makeFormFieldWrapper('hoursLabel', t(messages.hoursLabel.id), labelJSX, true)}
        {initialDays}
      </StandardForm>
    </Page>
  );
};
export default HoursPage;
