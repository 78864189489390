import React from 'react';
import { useSwipeable } from 'react-swipeable';

import './MobileSwipeBar.scss';

interface Props {
  onClose: () => void;
}

const App: React.FC<Props> = (props: Props) => {
  const swipeHandlers = useSwipeable({
    onSwipedDown: props.onClose,
  });

  return <div {...swipeHandlers} className="mobile-swipe-bar" />;
};

export default App;
