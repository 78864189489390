import React, { useRef, useEffect, useContext } from 'react';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';
import { H3, Typography } from '@vp/swan';
import SiteModel, { PageModel } from '../../models/SiteModel';
import { StepKind } from '../../enums/PageEnums';
import Features from '../ui/main/Features';
import ThemesPage from './ThemesPage';
import ThemesModel from '../../models/ThemesModel';
import MainButtonBar from '../ui/main/MainButtonBar';
import OnboardingContext from '../utils/OnboardingContext';
import './Main.scss';

const messages = defineMessages({
  prompt: 'Add features & modify your profile style.',
  promptFeatures: 'Add features',
  promptStyle: 'Modify your profile style',
  editStyles: 'Edit Styles',
  close: 'Close',
  nextStep: 'Next Step',
  galleryModel: 'Photo gallery',
  uploadPDFModel: 'Upload PDF',
  publish: 'Publish',
  Features: 'Features',
  Style: 'Style',
});

interface MainPageProps {
  onSubmit: (formData: PageModel) => void;
  siteModel: SiteModel;
  handleDelete: (index: number) => void;
  handleReorder: (indexA: number, indexB: number) => void;
  confirmReorder: () => void;
  hideMobileEditor: () => void;
  setCurrentTab: (step: StepKind) => void;
  onStepChange: (newSelectedStep: string) => void;
  currentTab: StepKind;
}

const MainPage: React.FC<MainPageProps> = (props) => {
  const { t } = useTranslation();
  const mainElement = useRef<HTMLDivElement>(null);
  const { nextOnboardingStep } = useContext(OnboardingContext);

  useEffect(() => {
    const currentRef = mainElement.current;
    if (currentRef) {
      currentRef.scrollTop = 0;
    }
  }, [props.currentTab]);

  const selectedStep = props.currentTab;

  return (
    <div className="main" ref={mainElement}>
      <div className="main-button-header">
        <div
          className={`mobile-tab${selectedStep === StepKind.Features ? ' selected' : ''}`}
          onClick={() => props.setCurrentTab(StepKind.Features)}
        >
          {t(messages.promptFeatures.id)}
        </div>
        <div
          className={`mobile-tab-styles mobile-tab${
            selectedStep === StepKind.Style ? ' selected' : ''
          }`}
          onClick={() => {
            props.setCurrentTab(StepKind.Style);
            nextOnboardingStep();
          }}
        >
          {t(messages.editStyles.id)}
        </div>
      </div>
      {selectedStep === StepKind.Style ? (
        <Typography component="h3" className="page-title-style">
          {t(messages.promptStyle.id)}
        </Typography>
      ) : (
        ''
      )}
      <H3 fontWeight="bolder" className="page-title-main">
        {t(messages.prompt.id)}
      </H3>
      <div className="button-bar-container">
        <MainButtonBar selectedStep={selectedStep} onStepChange={props.onStepChange} />
      </div>
      {selectedStep === StepKind.Features && (
        <Features
          translate={t}
          siteModel={props.siteModel}
          handleDelete={props.handleDelete}
          handleReorder={props.handleReorder}
          confirmReorder={props.confirmReorder}
        />
      )}
      {selectedStep === StepKind.Style && (
        <ThemesPage
          onSubmit={props.onSubmit}
          formData={props.siteModel.find((k) => k.key === 'themesModel')?.data as ThemesModel}
        />
      )}
    </div>
  );
};

export default MainPage;
