import React from 'react';
import { SelectionSetInput, SelectionSetLabel } from '@vp/swan';
import {
  VisuallyHidden,
  StandardTile,
  StandardTileContents,
  StandardTileImage,
  StandardTileName,
} from '@vp/swan';
import './ThemeLayoutButton.scss';

interface Props {
  accessibleDescription: string;
  value: string;
  tileName: string;
  imageURL: string;
  imageAlt: string;
}
const ThemeLayoutButton: React.FC<Props> = ({
  accessibleDescription,
  value,
  tileName,
  imageURL,
  imageAlt,
}) => (
  <div className="themelayoutbutton-container">
    <VisuallyHidden>{accessibleDescription}</VisuallyHidden>
    <SelectionSetInput value={value}>
      <SelectionSetLabel>
        <StandardTile>
          <StandardTileImage>
            <img className="themelayoutbutton-image" src={imageURL} alt={imageAlt} />
          </StandardTileImage>
          <StandardTileContents>
            <StandardTileName>{tileName}</StandardTileName>
          </StandardTileContents>
        </StandardTile>
      </SelectionSetLabel>
    </SelectionSetInput>
  </div>
);

export default ThemeLayoutButton;
