import React, { useEffect, useContext } from 'react';
import { SiteContent, SiteMain } from '@vp/swan';
import { initTrackingWithKeys } from '@vp/digital-tracking-lib';
import { AnalyticsContext, trackingAssetName } from './hooks/AnalyticsProvider';

import './AppContainer.scss';

const AppContainer: React.FC = (props) => {
  const { trackingKeys, trackBuilderPageLoad, trackBuilderProductViewed } =
    useContext(AnalyticsContext);
  const locale = window.csbData.culture;

  // Analytics
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      initTrackingWithKeys(locale, trackingKeys!, trackingAssetName!);
      trackBuilderPageLoad();
      trackBuilderProductViewed();
    }
  }, [locale, trackingKeys]);

  return (
    <SiteContent>
      <SiteMain>
        <div className="app-container">{props.children}</div>
      </SiteMain>
    </SiteContent>
  );
};

export default AppContainer;
