import React from 'react';

export interface RouteParams {
  [key: string]: string;
}

export interface RouterState {
  pathname: string;
  params: RouteParams;
}

const RouterContext = React.createContext({
  pathname: '/',
  params: {},
  changePage: (path: string, params?: RouteParams) => {}, // eslint-disable-line
});

export default RouterContext;
