// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { CustomPicker, ColorChangeHandler } from 'react-color';
import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common';
import { InjectedColorProps } from 'react-color/lib/components/common/ColorWrap';
import { EditableInputStyles } from 'react-color/lib/components/common/EditableInput';
import { Button } from '@vp/swan';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';

import './ColorPicker.scss';

interface Props extends InjectedColorProps {
  onApply: () => void;
}

const messages = defineMessages({
  update: 'Update Color',
});

const inputStyle: EditableInputStyles = {
  input: {
    padding: '0',
    width: '100px',
    height: '30px',
    boxSizing: 'border-box',
    fontSize: '12px',
    fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
    border: '1px solid #d9d9d9',
    borderRadius: '3px',
    color: '#333333',
    outline: 'none',
  },
};

const saturationPointer = () => <div className="custom-color-picker__saturation-pointer" />;

const huePointer = () => <div className="custom-color-picker__hue-pointer" />;

// react-color types are wrong/incomplete
// The `hsv` prop is not present at all and the Saturation/Hue components
// incorrectly expect `hsl` to be inside a `color` object
// @TODO: Open an MR to fix this in definitelyTyped
const CustomColorPicker: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { hsl, hsv, hex, onChange, onApply } = props;

  return (
    <div className="custom-color-picker">
      <div className="custom-color-picker__saturation">
        <Saturation
          hsl={hsl}
          hsv={hsv}
          pointer={saturationPointer}
          onChange={onChange as ColorChangeHandler}
        />
      </div>
      <div className="custom-color-picker__hue">
        <Hue hsl={hsl} pointer={huePointer} onChange={onChange as ColorChangeHandler} />
      </div>
      <div className="custom-color-picker__selection">
        <EditableInput style={inputStyle} value={hex} onChange={onChange} />
        <Button className="colorpalette-textbutton" skin="primary" onClick={onApply}>
          {t(messages.update.id)}
        </Button>
      </div>
    </div>
  );
};

export default CustomPicker(CustomColorPicker);
