import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import Page from '../ui/Page';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import SocialLinksModel, { socialLinksSchema } from '../../models/SocialLinksModel';
import PageBaseProps from './PageBase';
import { StandardForm } from '@vp/swan';
import {
  FormStateModel,
  inputGenerators,
  isValidForm,
  preventEnterSubmission,
} from '../utils/FormUtils';
import DropDownField from '../ui/DropDownField';
import SocialNetworks, { SocialNetworkKey } from '../data/SocialNetworks';
import { isWebsite } from '../utils/ValidationUtils';
import { v4 as uuidv4 } from 'uuid';

const HANDLE = '@';
const URL_PATTERN = /http(s)?:\/\/.*/;

const messages = defineMessages({
  title: 'Social links',
  desc: 'Add the username or handle of your social links',
  help: 'See where to find this link',
  platform: 'Select social platform to add',
  platformPlaceholder: 'Choose a social platform',
  label: 'What do you want to name this section?',
  facebook: 'Facebook',
  twitter: 'Twitter',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  youtube: 'Youtube',
  vimeo: 'Vimeo',
  soundcloud: 'SoundCloud',
  url: 'URL',
  handle: 'Handle',
});

const SocialLinksPage: React.FC<PageBaseProps<SocialLinksModel>> = (props) => {
  const { t } = useTranslation();

  const schema = { ...socialLinksSchema };
  const initialData = props.formData || {
    platform: t(messages.platformPlaceholder.id).toString(),
    label: '',
    link: '',
    id: uuidv4(),
    url: '',
  };

  if ((initialData.link as string).startsWith(HANDLE)) {
    initialData.link = (initialData.link as string).substring(1);
  }

  const [socialLinks, setSocialLinks] = useState<FormStateModel<SocialLinksModel>>({
    data: initialData,
    errors: {
      platform: null,
      label: null,
      link: null,
      id: null,
      url: null,
    },
    dirty: {
      platform: !isEmpty(initialData.platform),
      label: !isEmpty(initialData.label),
      link: !isEmpty(initialData.link),
      id: !isEmpty(initialData.id),
      url: !isEmpty(initialData.url),
    },
  });

  const updateSchema = () => {
    const type = SocialNetworks[socialLinks.data.platform as SocialNetworkKey]?.type;
    schema.link.validator = type === 'url' ? isWebsite : null;
    schema.link.validatorError =
      type === 'url' ? 'client.components.utils.ValidationUtils.website' : null;
  };
  updateSchema();

  const handleSubmit = () => {
    let link = socialLinks.data.link as string;
    const type = SocialNetworks[socialLinks.data.platform as SocialNetworkKey]?.type;
    const baseUrl = SocialNetworks[socialLinks.data.platform as SocialNetworkKey].baseUrl;

    const url =
      type === 'handle' ? `${baseUrl}${link}` : link.match(URL_PATTERN) ? link : `//${link}`;
    link = type === 'handle' ? `${HANDLE}${link}` : link;

    const data = { ...socialLinks.data, link, url };

    props.onSubmit({ key: 'socialLinksModel', data });
  };
  const enableSubmit = (): boolean => {
    return isValidForm<SocialLinksModel>(socialLinks.data, schema);
  };

  const idPrefix = `client.components.pages.SocialLinksPage`;
  const handlePlatformChange = (platform: string) => {
    const newModel = { ...socialLinks };
    newModel.data.platform = platform;
    newModel.data.label = t(`${idPrefix}.${platform}`).toString();
    newModel.errors.label = null;
    newModel.dirty.platform = true;
    newModel.dirty.label = true;
    updateSchema();
    setSocialLinks(newModel);
  };

  const makeLinkLabel = () => {
    const platform = t(`${idPrefix}.${socialLinks.data.platform}`);
    const type = t(
      `${idPrefix}.${SocialNetworks[socialLinks.data.platform as SocialNetworkKey]?.type}`
    );
    return `${platform} ${type}`;
  };

  const getLinkIcon = () => {
    const type = SocialNetworks[socialLinks.data.platform as SocialNetworkKey]?.type;
    return type === 'handle' ? `${window.vbcConfig.host}/images/at.svg` : '';
  };

  const help = SocialNetworks[socialLinks.data.platform as SocialNetworkKey]?.help;
  const placeholder = t(SocialNetworks[socialLinks.data.platform as SocialNetworkKey]?.placeholder);

  return (
    <Page
      title={t(messages.title.id)}
      desc={t(messages.desc.id)}
      enableSubmit={enableSubmit}
      handleSubmit={handleSubmit}
      isNewBlock={!props.formData}
    >
      <StandardForm className="vbc-form" onKeyPress={preventEnterSubmission}>
        <DropDownField
          value={socialLinks.data.platform as string}
          possibleValues={Object.keys(SocialNetworks)}
          fieldLabel={messages.platform.id}
          idPrefix={idPrefix}
          updateValue={handlePlatformChange}
          placeholder={messages.platformPlaceholder.id}
          className={'vbc-form-label-required'} // @TODO the asterisk is not showing up
        />
        {inputGenerators[socialLinksSchema.label.type](
          socialLinks,
          socialLinksSchema,
          'label',
          t(messages.label.id).toString(),
          '',
          t,
          setSocialLinks
        )}

        {SocialNetworks[socialLinks.data.platform as SocialNetworkKey] &&
          inputGenerators[socialLinksSchema.link.type](
            socialLinks,
            socialLinksSchema,
            'link',
            makeLinkLabel(),
            getLinkIcon(),
            t,
            setSocialLinks,
            placeholder
          )}
      </StandardForm>

      {help && (
        <a href={help} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          {t(messages.help.id)} &#8594;
        </a>
      )}
    </Page>
  );
};

export default SocialLinksPage;
