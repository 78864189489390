import React, { useRef, useContext, useState } from 'react';
import { useDrag, useDrop, XYCoord, DropTargetMonitor } from 'react-dnd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Typography, Card, FlexBox } from '@vp/swan';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import RouterContext from '../utils/RouterContext';
import Pages from '../data/Pages';
import DropDownMenu, { DropDownMenuItem } from './DropDownMenu';
import EqualsIcon from '../../images/equals.svg';
import PlusIcon from '../../images/plus.svg';
import MoreBoldIcon from '../../images/more-bold.svg';
import { Identifier } from 'dnd-core';

import './LinkCard.scss';

interface LinkCardProps {
  to: { page: Pages; id: string | undefined };
  text: string;
  hint: string;
  index: number;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onDelete?: (e: React.MouseEvent<HTMLElement>) => void;
  handleReorder: (indexA: number, indexB: number) => void;
  confirmReorder: () => void;
  hasData: boolean;
}

interface DragItem {
  text: string;
  index: number;
}

interface CollectedProp {
  handlerId: Identifier | null;
}

const messages = defineMessages({
  edit: 'Update',
  delete: 'Delete',
});

const DRAG_TYPE = 'draggableCard';
const AUTO_SCROLL_DISTANCE = 65;
const SCROLL_AREA_HEIGHT = 40;
// Height in px of editor container tabs
const EDITOR_PADDING = 67;

const LinkCard: React.FC<LinkCardProps> = (props: LinkCardProps) => {
  const { t } = useTranslation();
  const { text, index, handleReorder } = props;
  const { changePage } = useContext(RouterContext);
  const cardRef = useRef<HTMLElement>(null);

  const dragRef = useRef<HTMLDivElement>(null);

  const [showHint, setShowHint] = useState(false);

  const goToPage = (e: React.MouseEvent<HTMLElement>) => {
    if (props.onClick) {
      props.onClick(e);
    }

    if (props.to.id) {
      changePage(props.to.page, { id: props.to.id });
    } else {
      changePage(props.to.page);
    }
  };

  const dropDownMenuItems: Array<DropDownMenuItem> = [
    {
      label: t(messages.edit.id),
      action: goToPage,
    },
    'divider',
    {
      label: t(messages.delete.id),
      action: props.onDelete,
    },
  ];

  const [{ handlerId }, initializeDrop] = useDrop<DragItem, unknown, CollectedProp>({
    accept: DRAG_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      // Determine mouse position
      const yOffset = (monitor.getClientOffset() as XYCoord).y;

      const editorContainer = document.getElementById('editor-container');

      // Scroll containing element if the cursor is near the edges
      if (window.isMobile && editorContainer) {
        const topOffset =
          window.innerHeight - editorContainer.clientHeight + EDITOR_PADDING + SCROLL_AREA_HEIGHT;
        const bottomOffset = window.innerHeight - SCROLL_AREA_HEIGHT;

        if (yOffset >= bottomOffset) {
          editorContainer.scrollBy({
            top: AUTO_SCROLL_DISTANCE,
            left: 0,
            behavior: 'smooth',
          });
        } else if (yOffset < topOffset) {
          editorContainer.scrollBy({
            top: AUTO_SCROLL_DISTANCE * -1,
            left: 0,
            behavior: 'smooth',
          });
        }
      }

      if (!cardRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = cardRef.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get pixels to the top
      const hoverClientY = yOffset - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      handleReorder(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop() {
      props.confirmReorder();
    },
  });

  const [{ isDragging }, initializeDrag] = useDrag({
    type: DRAG_TYPE,
    item: (): DragItem => {
      return {
        text,
        index,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const topLevelClasses = classNames('link-card', {
    'is-dragging': isDragging,
    'has-hint': !props.hasData,
  });

  if (props.hasData) {
    if (window.isMobile) {
      initializeDrag(initializeDrop(cardRef));
    } else {
      initializeDrag(dragRef);
      initializeDrop(cardRef);
    }
  }

  const [dropDownOpened, setDropDownOpened] = useState(false);

  return (
    <Card
      marginBottom={5}
      className={topLevelClasses}
      ref={cardRef}
      data-handler-id={handlerId}
      onClick={!props.hasData ? goToPage : undefined}
      onMouseEnter={!props.hasData ? () => setShowHint(true) : undefined}
      onMouseLeave={!props.hasData ? () => setShowHint(false) : undefined}
    >
      <FlexBox justifyContent="space-between" alignItems="center" className="card-content">
        {props.hasData ? (
          <div className="feature-icon-container" ref={dragRef}>
            <EqualsIcon className="feature-text-icon" />
          </div>
        ) : (
          <div className="feature-icon-container">
            <PlusIcon className="feature-text-icon" />
          </div>
        )}
        <div
          className={classNames('card-text', { 'show-hint': showHint })}
          onClick={!props.hasData ? goToPage : undefined}
        >
          {!props.hasData && (
            <Typography fontSize={1} className="hint">
              {props.hint}
            </Typography>
          )}
          <Typography fontSize={2}>{text}</Typography>
        </div>
        {props.hasData && (
          <DropDownMenu items={dropDownMenuItems} open={dropDownOpened} setOpen={setDropDownOpened}>
            <MoreBoldIcon className="dropdown-icon" onClick={() => setDropDownOpened(true)} />
          </DropDownMenu>
        )}
      </FlexBox>
    </Card>
  );
};

export default LinkCard;
