import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectionSet, GridContainer, Row, Typography } from '@vp/swan';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import ThemeLayoutButton from './ThemeLayoutButton';

import './ThemeStyle.scss';

interface StyleProps {
  layout: string;
  onLayoutChange: (layout: string) => void;
}

const messages = defineMessages({
  style: 'Layout',
  profile: 'Profile',
  linkButtons: 'Link buttons',
  profileTemplateStyle: 'Card template will have a profile layout',
  linkedButtonsTemplateStyle: 'Card template will have a layout with link buttons',
});

const ThemeStyle: React.FC<StyleProps> = ({ layout, onLayoutChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        className="theme-style-header"
        weight="bold"
        fontSize={3}
        marginBottom={2}
        marginTop={1}
      >
        {t(messages.style.id)}
      </Typography>
      <div className="styleToggle-container">
        <SelectionSet
          variant="single-select"
          selectedValue={layout}
          onSelectedValueChange={(newSelectedValue) => {
            if (newSelectedValue) {
              onLayoutChange(newSelectedValue);
            }
          }}
        >
          <GridContainer>
            <Row>
              <ThemeLayoutButton
                accessibleDescription={t(messages.profileTemplateStyle.id)}
                value="profile"
                imageURL={`${window.vbcConfig.host}/images/profile.png`}
                imageAlt="profile"
                tileName={t(messages.profile.id)}
              />
              <ThemeLayoutButton
                accessibleDescription={t(messages.linkedButtonsTemplateStyle.id)}
                value="linkButtons"
                imageURL={`${window.vbcConfig.host}/images/linkButtons.png`}
                imageAlt="link-buttons"
                tileName={t(messages.linkButtons.id)}
              />
            </Row>
          </GridContainer>
        </SelectionSet>
      </div>
    </>
  );
};
export default ThemeStyle;
