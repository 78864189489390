import { useWebAuth } from '@vp/digital-auth-lib';
import { AlertBox } from '@vp/swan';
import React from 'react';
import './BookendsHeader.scss';
import { Button, Typography } from '@vp/swan';
const CareAgentBookendsHeader = () => {
  const auth = useWebAuth();
  return (
    <AlertBox skin="warning">
      <Typography> You are signed in as care agent </Typography>
      <Button sizeVariant="mini" onClick={() => auth.signOut()} skin="secondary">
        Sign out
      </Button>
    </AlertBox>
  );
};

export default CareAgentBookendsHeader;
