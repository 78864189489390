/*
 * helper functions to simplify creation of html forms
 */
import React, { KeyboardEvent, useContext } from 'react';
import {
  TextInput,
  FormLabel,
  TextArea,
  ResponsiveImage,
  StandardForm,
  FormField,
  FormInputGroup,
  FormError,
} from '@vp/swan';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import CroppedImage, { CropBox } from '../../models/CroppedImageModel';
import ImageInput from '../ui/imageInput';
import { Background, ButtonDesign } from '../../models/ThemesModel';
import PhoneInput from 'react-phone-number-input';
import { AssetStoreContext } from '../hooks/AssetStoreProvider';
import { useLocale } from '@vp/digital-locale-lib';
import { CountryCode } from 'libphonenumber-js/types';

import 'react-phone-number-input/style.css';
import './FormUtils.scss';

export type FormStateModel<T> = {
  data: FormDataModel<T>;
  errors: FormErrorModel<T>;
  dirty: FormDirtyModel<T>;
  meta?: any;
};

export type FormDataModel<T> = {
  [key in keyof T]:
    | string
    | number
    | string[]
    | Date
    | CroppedImage
    | ButtonDesign
    | Background
    | null
    | boolean;
};

export type FormErrorModel<T> = {
  [key in keyof T]: string | null;
};

export type FormDirtyModel<T> = {
  [key in keyof T]: boolean;
};

export type FormSchema<T> = {
  [key in keyof T]: InputSchema;
};

export type InputSchema = {
  type: 'text' | 'paragraph' | 'image' | 'croppedImage' | 'phone' | 'ignore';
  required: boolean;
  validator: ((value: string | number) => boolean) | null;
  validatorError: string | null;
  placeholder?: string;
};

export const inputGenerators = {
  text: makeInputField,
  paragraph: makeTextAreaField,
  image: makeImageField,
  croppedImage: makeCroppedImageField,
  phone: makePhoneFiled,
  ignore: makeIgnoredField,
};

// automatically create an HTML form, with text inputs for each
// corresponding field in T, which is a FormDataModel object
export function makeSimpleForm<T>(
  // form state object
  formState: FormStateModel<T>,
  formSchema: FormSchema<T>,
  translate: (key: string) => string,
  idPrefix: string,
  // useState() setter for T
  setter: React.Dispatch<React.SetStateAction<FormStateModel<T>>>
): JSX.Element {
  const fields = Object.keys(formState.data).map((key) => {
    const inputType = formSchema[key as keyof T].type;
    if ('ignore' === inputType) {
      return;
    }
    const inputGenerator = inputGenerators[inputType];
    const placeholder = formSchema[key as keyof T].placeholder;
    return inputGenerator<T>(
      formState,
      formSchema,
      key as keyof T,
      translate(`${idPrefix}.${key}`),
      '',
      translate,
      setter,
      placeholder ? translate(placeholder) : undefined
    );
  });
  return (
    <StandardForm className="vbc-form" onKeyPress={preventEnterSubmission}>
      {fields}
    </StandardForm>
  );
}

function makeInputField<T>(
  formState: FormStateModel<T>,
  formSchema: FormSchema<T>,
  key: keyof T,
  fieldLabel: string,
  fieldIcon: string,
  translate: (key: string) => string,
  setter: React.Dispatch<React.SetStateAction<FormStateModel<T>>>,
  fieldPlaceholder?: string
): JSX.Element {
  const schema = formSchema[key];
  const error = formState.errors[key];
  const isDirty = formState.dirty[key];

  const fieldJSX = (
    <>
      <div className="input-container">
        {fieldIcon && (
          <div className="icon-container">
            <ResponsiveImage src={fieldIcon} />
          </div>
        )}
        <TextInput
          value={formState.data[key] as string | number | string[]}
          // @TODO cleanup duplicate code
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            let newFormState: FormStateModel<T> = { ...formState };
            newFormState.data[key] = value;
            if (isDirty) {
              newFormState = handleInputValidation(newFormState, schema, key, value);
            }
            setter(newFormState);
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            if (!isDirty) {
              const newFormState = handleInputValidation(formState, schema, key, value);
              setter(newFormState);
            }
          }}
          className={fieldIcon ? 'input-with-icon' : ''}
          placeholder={fieldPlaceholder}
        />
      </div>
      {error && <FormError>{translate(error as string)}</FormError>}
    </>
  );
  return makeFormFieldWrapper(key as string, fieldLabel, fieldJSX, schema.required);
}

function makeTextAreaField<T>(
  formState: FormStateModel<T>,
  formSchema: FormSchema<T>,
  key: keyof T,
  fieldLabel: string,
  fieldIcon: string,
  translate: (key: string) => string,
  setter: React.Dispatch<React.SetStateAction<FormStateModel<T>>>,
  fieldPlaceholder?: string
): JSX.Element {
  const schema = formSchema[key];
  const error = formState.errors[key];
  const isDirty = formState.dirty[key];

  const fieldJSX = (
    <>
      <TextArea
        className="vbc-text-area"
        rows={5}
        fullWidth={true}
        value={formState.data[key] as string | number | string[]}
        onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
          const value = e.currentTarget.value;
          let newFormState: FormStateModel<T> = { ...formState };
          newFormState.data[key] = value;
          if (isDirty) {
            newFormState = handleInputValidation(newFormState, schema, key, value);
          }
          setter(newFormState);
        }}
        onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => {
          const value = e.currentTarget.value;
          if (!isDirty) {
            const newFormState = handleInputValidation(formState, schema, key, value);
            setter(newFormState);
          }
        }}
        placeholder={fieldPlaceholder}
      />
      {error && <FormError>{translate(error as string)}</FormError>}
    </>
  );
  return makeFormFieldWrapper(key as string, fieldLabel, fieldJSX, schema.required);
}

function makeImageField<T>(
  formState: FormStateModel<T>,
  formSchema: FormSchema<T>,
  key: keyof T,
  fieldLabel: string,
  fieldIcon: string,
  translate: (key: string) => string,
  setter: React.Dispatch<React.SetStateAction<FormStateModel<T>>>
): JSX.Element {
  const schema = formSchema[key];
  const error = formState.errors[key];
  const { uploadAsset } = useContext(AssetStoreContext);

  const handleChange = async (image: File) => {
    try {
      const imageUrl = await uploadAsset(image);
      const newFormState: FormStateModel<T> = { ...formState };
      if (imageUrl) {
        newFormState.data[key] = imageUrl;
      } else {
        newFormState.errors[key] = 'client.components.utils.ValidationUtils.genericError';
      }

      newFormState.dirty[key] = true;
      setter({ ...newFormState });
    } catch (e) {
      const msg = 'Error while uploading file' + e.message;
      console.error(msg); // eslint-disable-line no-console
      throw new Error(msg);
    }
  };

  const handleRemove = () => {
    const newFormState: FormStateModel<T> = { ...formState };
    newFormState.data[key] = '';
    setter({ ...newFormState });
  };

  const fieldJSX = (
    <>
      <ImageInput
        key={key as string}
        value={formState.data[key] as string}
        onChange={handleChange}
        remove={handleRemove}
      />
      {error && <FormError>{translate(error as string)}</FormError>}
    </>
  );
  return makeFormFieldWrapper(key as string, fieldLabel, fieldJSX, schema.required);
}

function makeCroppedImageField<T>(
  formState: FormStateModel<T>,
  formSchema: FormSchema<T>,
  key: keyof T,
  fieldLabel: string,
  fieldIcon: string,
  translate: (key: string) => string,
  setter: React.Dispatch<React.SetStateAction<FormStateModel<T>>>
): JSX.Element {
  const schema = formSchema[key];
  const error = formState.errors[key];
  const imageUrl = (formState.data[key] as any)?.url || formState.data[key];
  const { uploadAsset } = useContext(AssetStoreContext);

  const handleImageChange = async (image: File) => {
    try {
      const newImageUrl = await uploadAsset(image);
      const newFormState: FormStateModel<T> = { ...formState };
      if (isString(newImageUrl)) {
        newFormState.data[key] = { url: newImageUrl, mimeType: image?.type };
      } else {
        newFormState.errors[key] = 'client.components.utils.ValidationUtils.genericError';
      }

      newFormState.dirty[key] = true;
      setter({ ...newFormState });
    } catch (e) {
      const msg = 'Error while uploading file' + e.message;
      console.error(msg);
      throw new Error(msg);
    }
  };

  const handleCrop = async (cropBox: CropBox) => {
    const newFormState: FormStateModel<T> = { ...formState };
    newFormState.data[key] = {
      url: imageUrl,
      cropBox,
      mimeType: (newFormState.data[key] as CroppedImage)?.mimeType,
    };
    setter({ ...newFormState });
  };

  const handleRemove = () => {
    const newFormState: FormStateModel<T> = { ...formState };
    newFormState.data[key] = null;
    setter({ ...newFormState });
  };

  const fieldJSX = (
    <>
      <ImageInput
        key={key as string}
        value={imageUrl}
        onChange={handleImageChange}
        remove={handleRemove}
        enableCropper={true}
        onCrop={handleCrop}
        cropData={(formState.data[key] as any)?.cropBox}
        acceptGif={true}
      />
      {error && <FormError>{translate(error as string)}</FormError>}
    </>
  );
  return makeFormFieldWrapper(key as string, fieldLabel, fieldJSX, schema.required);
}

function makePhoneFiled<T>(
  formState: FormStateModel<T>,
  formSchema: FormSchema<T>,
  key: keyof T,
  fieldLabel: string,
  fieldIcon: string,
  translate: (key: string) => string,
  setter: React.Dispatch<React.SetStateAction<FormStateModel<T>>>
): JSX.Element {
  const schema = formSchema[key];
  const error = formState.errors[key];
  const inputField = document.getElementsByClassName('PhoneInputInput')[0];
  const country = useLocale().identifier.substring(3) as CountryCode;

  const phoneField = (
    <PhoneInput
      role="input"
      aria-label="Phone input"
      aria-required="false"
      defaultCountry={country}
      value={formState.data[key] as string}
      onChange={(phone) => {
        let newFormState: FormStateModel<T> = { ...formState };
        newFormState.data[key] = phone?.toString() || null;
        newFormState = handleInputValidation(newFormState, schema, key, phone?.toString() ?? '');
        newFormState.errors[key]
          ? inputField?.classList.add('is-invalid')
          : inputField?.classList.remove('is-invalid');
        setter(newFormState);
      }}
      //removed the onBlur validation because the e.taget value doesn't include the code country and will always be invalid
    />
  );
  const fieldJSX = (
    <>
      {phoneField}
      {error && <FormError>{translate(error as string)}</FormError>}
    </>
  );
  return makeFormFieldWrapper(key as string, fieldLabel, fieldJSX, schema.required);
}

/* eslint-disable no-empty-pattern */
// this function signature should match the other makeXXField()
function makeIgnoredField<T>(
  {}: FormStateModel<T>,
  {}: FormSchema<T>,
  key: keyof T,
  {}: string,
  {}: string,
  {}: (key: string) => string,
  {}: React.Dispatch<React.SetStateAction<FormStateModel<T>>>
): JSX.Element {
  throw new Error(`Can't make the ignored field '${key}'`);
}
/* eslint-enable no-empty-pattern */

export function makeFormFieldWrapper(
  key: string,
  fieldLabel: string,
  fieldJSX: JSX.Element,
  required: boolean
): JSX.Element {
  return (
    <FormField key={key}>
      <FormLabel className={required ? 'vbc-form-label-required' : 'vbc-form-label'}>
        {`${fieldLabel}`}
      </FormLabel>
      <FormInputGroup>{fieldJSX}</FormInputGroup>
    </FormField>
  );
}

function handleInputValidation<T>(
  formState: FormStateModel<T>,
  schema: InputSchema,
  key: keyof T,
  value: string
): FormStateModel<T> {
  const validator = schema.validator;
  if (!validator && !schema.required) {
    return formState;
  }
  const newFormErrors: FormErrorModel<T> = { ...formState.errors };
  if (validator && !validator(value)) {
    newFormErrors[key] = schema.validatorError;
  } else if (schema.required && isEmpty(value)) {
    newFormErrors[key] = 'client.components.utils.ValidationUtils.required';
  } else {
    newFormErrors[key] = null;
  }
  const newFormDirty: FormDirtyModel<T> = { ...formState.dirty };
  newFormDirty[key] = true;
  if (formState.meta) {
    return {
      data: formState.data,
      errors: newFormErrors,
      dirty: newFormDirty,
      meta: formState.meta,
    };
  }
  return { data: formState.data, errors: newFormErrors, dirty: newFormDirty };
}

export function isValidForm<T>(data: FormDataModel<T>, schema: FormSchema<T>) {
  return Object.keys(data).every((field) => {
    const value = data[field as keyof T];
    const required = schema[field as keyof T].required;
    const validator = schema[field as keyof T].validator;

    return !(validator && !validator(value as string | number)) && !(required && isEmpty(value));
  });
}

export function preventEnterSubmission(e: KeyboardEvent<HTMLElement>): void {
  if (e.key == 'Enter') {
    e.preventDefault();
  }
}
