import AboutModel from './AboutModel';
import CallNowModel from './CallNowModel';
import ContactsModel from './ContactsModel';
import ThemesModel from './ThemesModel';
import SocialLinksModel from './SocialLinksModel';
import VideoModel from './VideoModel';
import WebsiteModel from './WebsiteModel';
import OperationHoursModel from './OperationHoursModel';
import GalleryModel from './GalleryModel';
import CustomLinkModel from './CustomLinkModel';
import Pages from '../components/data/Pages';
import uploadPDFModel from './UploadPDFModel';
import AddressModel from './AddressModel';
import EmailUsModel from './EmailUsModel';

export type SiteModelKeys =
  | 'aboutModel'
  | 'callNowModel'
  | 'emailUsModel'
  | 'contactsModel'
  | 'customLinkModel'
  | 'operationHoursModel'
  | 'socialLinksModel'
  | 'themesModel'
  | 'videoModel'
  | 'websiteModel'
  | 'galleryModel'
  | 'uploadPDFModel'
  | 'addressModel';

export type PageModels =
  | AboutModel
  | CallNowModel
  | EmailUsModel
  | ContactsModel
  | CustomLinkModel
  | OperationHoursModel
  | SocialLinksModel
  | ThemesModel
  | WebsiteModel
  | VideoModel
  | GalleryModel
  | uploadPDFModel
  | AddressModel;

export type PageModel = {
  key: SiteModelKeys;
  data: PageModels;
};

export function findPageModel(key: SiteModelKeys, siteModel: SiteModel): PageModel | undefined {
  if (!siteModel || !siteModel.find) {
    return undefined;
  }

  return siteModel.find((k) => k.key === key);
}

type ButtonMapping = Record<SiteModelKeys, Pages>;

export const ButtonModels: ButtonMapping = {
  emailUsModel: Pages.EmailUs,
  contactsModel: Pages.Contacts,
  aboutModel: Pages.About,
  callNowModel: Pages.CallNow,
  operationHoursModel: Pages.Hours,
  socialLinksModel: Pages.Social,
  videoModel: Pages.Video,
  websiteModel: Pages.Website,
  galleryModel: Pages.Gallery,
  themesModel: Pages.Theme,
  customLinkModel: Pages.CustomLink,
  uploadPDFModel: Pages.UploadPDF,
  addressModel: Pages.Address,
};

type SiteModel = PageModel[];
export default SiteModel;
