import omit from 'lodash/omit';
import { vpAccessTokenRequest } from './axios';
import UserModel from '../models/UserModel';
import SiteModel from '../models/SiteModel';
import ConnectedProduct from '../../server/model/ConnectedProduct';

const apiRoute = `${window.vbcConfig.host}/api`;

export async function fetchUserByConnectedProduct(opts: {
  connectedProductAltId?: string | null;
  workId?: string | null;
}): Promise<UserModel | null> {
  let res;
  try {
    res = await vpAccessTokenRequest({
      method: 'get',
      url: `${apiRoute}/v1/connectedProduct`,
      params: { altId: opts.connectedProductAltId, workId: opts.workId },
    });
  } catch (e) {
    // TODO this now returns 403 instead of 404
    if (e.response?.status !== 404 && e.response?.status !== 403) {
      throw e;
    }
  }

  return res?.data
    ? {
        ...(res.data as any).user,
        connectedProduct: omit(res.data as ConnectedProduct, 'user'),
      }
    : null;
}

export async function updateSiteData(
  connectedProductAltId: string,
  siteData: SiteModel
): Promise<void> {
  await vpAccessTokenRequest({
    method: 'put',
    url: `${apiRoute}/v1/connectedProduct/${connectedProductAltId}`,
    data: { siteModel: siteData },
  });
}

export async function preview(connectedProductAltId: string, data: SiteModel): Promise<any> {
  return await vpAccessTokenRequest({
    method: 'post',
    url: `${apiRoute}/v1/connectedProduct/${connectedProductAltId}/preview`,
    data,
  });
}

export async function publish(connectedProductAltId: string, data: SiteModel): Promise<any> {
  return await vpAccessTokenRequest({
    method: 'post',
    url: `${apiRoute}/v1/connectedProduct/${connectedProductAltId}/publish`,
    data,
  });
}

export async function geoCodeAddress(
  connectedProductAltId: string,
  address: string,
  language = 'en'
) {
  const response = await vpAccessTokenRequest({
    method: 'post',
    url: `${apiRoute}/v1/connectedProduct/${connectedProductAltId}/addresses/geocode`,
    data: { address, language },
  });

  return { lat: response.data.lat as number, lng: response.data.lng as number };
}
