import React, { useState } from 'react';
import Page from '../ui/Page';
import { makeSimpleForm, isValidForm, FormStateModel } from '../utils/FormUtils';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import CallNowModel, { callNowSchema } from '../../models/CallNowModel';
import PageBaseProps from './PageBase';
import isEmpty from 'lodash/isEmpty';

const messages = defineMessages({
  title: 'Call now',
  desc: 'Add a number you would like to be called at',
  phone: 'Phone Number',
  label: 'What do you want to name this section?',
});

const CallNowPage: React.FC<PageBaseProps<CallNowModel>> = (props) => {
  const { t } = useTranslation();
  const initialData = props.formData || {
    label: t(messages.title.id).toString(),
    phone: null,
  };
  const [callNow, setCallNow] = useState<FormStateModel<CallNowModel>>({
    data: initialData,
    errors: {
      label: null,
      phone: null,
    },
    dirty: {
      label: !isEmpty(initialData.label),
      phone: !isEmpty(initialData.phone),
    },
  });

  const handleSubmit = () => {
    props.onSubmit({ key: 'callNowModel', data: callNow.data });
  };
  const enableSubmit = (): boolean => {
    return isValidForm<CallNowModel>(callNow.data, callNowSchema);
  };

  return (
    <Page
      title={t(messages.title.id)}
      desc={t(messages.desc.id)}
      enableSubmit={enableSubmit}
      handleSubmit={handleSubmit}
      isNewBlock={!props.formData}
    >
      {makeSimpleForm(callNow, callNowSchema, t, 'client.components.pages.CallNowPage', setCallNow)}
    </Page>
  );
};

export default CallNowPage;
