import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ThemeStyle from '../ui/theme/ThemeStyle';
import ThemesModel, {
  Background,
  BackgroundKind,
  ButtonDesign,
  ButtonKind,
} from '../../models/ThemesModel';
import PageBaseProps from './PageBase';
import ThemeBackground from '../ui/theme/background/ThemeBackground';
import { ColorList } from '../data/Theme';
import ThemeButtons from '../ui/theme/buttons/ThemeButtons';
import { trackStyleEvent } from '../utils/trackingUtils';

const ThemesPage: React.FC<PageBaseProps<ThemesModel>> = (props) => {
  const { t } = useTranslation();
  const initialButtonDesign = {
    shape: ButtonKind.square,
    color: ColorList.black,
    textColor: ColorList.white,
  };
  let initialForm = props.formData as ThemesModel;
  if (props.formData && !props.formData.buttonDesign) {
    initialForm = { ...props.formData, buttonDesign: initialButtonDesign };
  }

  const [themes, setThemes] = useState<ThemesModel>(
    initialForm || {
      layout: 'profile',
      background: {
        kind: BackgroundKind.color,
        color: ColorList.tangaroa,
      },
      buttonDesign: initialButtonDesign,
    }
  );

  const submitThemes = (themes: ThemesModel) => {
    props.onSubmit({ key: 'themesModel', data: themes });
    setThemes(themes);
  };

  const handleBackgroundChange = (background: Background) => {
    const newModel: ThemesModel = { ...themes, background: { ...background } };
    submitThemes(newModel);
  };

  const handleLayoutChange = (layout: string) => {
    trackStyleEvent('Layout', layout + ' selected');
    const newModel: ThemesModel = { ...themes, layout };
    submitThemes(newModel);
  };

  const handleButtonDesignChange = (buttonDesign: ButtonDesign) => {
    const newModel: ThemesModel = { ...themes, buttonDesign };
    submitThemes(newModel);
  };

  return (
    <section className="editor-section themes-page-section">
      <ThemeStyle layout={themes.layout as string} onLayoutChange={handleLayoutChange} />
      <br />
      {themes.layout === 'linkButtons' && (
        <ThemeButtons
          buttonDesign={themes.buttonDesign as ButtonDesign}
          t={t}
          onButtonDesignChange={handleButtonDesignChange}
        />
      )}
      <ThemeBackground
        background={themes.background as Background}
        onBackgroundChange={handleBackgroundChange}
      />
    </section>
  );
};

export default ThemesPage;
