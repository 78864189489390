import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Page from '../ui/Page';
import { makeSimpleForm, isValidForm, FormStateModel } from '../utils/FormUtils';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import CustomLinkModel, { customLinkSchema } from '../../models/CustomLinkModel';
import PageBaseProps from './PageBase';

const messages = defineMessages({
  title: 'Custom link',
  desc: 'Add a link to any url.',
  url: 'URL',
  label: 'What do you want to name this section?',
});

const CustomLinkPage: React.FC<PageBaseProps<CustomLinkModel>> = (props) => {
  const { t } = useTranslation();

  const initialData = props.formData || {
    id: uuidv4(),
    label: t(messages.title.id).toString(),
    url: '',
  };
  const [customLink, setCustomLink] = useState<FormStateModel<CustomLinkModel>>({
    data: initialData,
    errors: {
      id: null,
      label: null,
      url: null,
    },
    dirty: {
      id: !isEmpty(initialData.id),
      label: !isEmpty(initialData.label),
      url: !isEmpty(initialData.url),
    },
  });

  const handleSubmit = () => {
    props.onSubmit({ key: 'customLinkModel', data: customLink.data });
  };
  const enableSubmit = (): boolean => {
    return isValidForm<CustomLinkModel>(customLink.data, customLinkSchema);
  };

  return (
    <Page
      title={t(messages.title.id)}
      desc={t(messages.desc.id)}
      enableSubmit={enableSubmit}
      handleSubmit={handleSubmit}
      isNewBlock={!props.formData}
    >
      {makeSimpleForm(
        customLink,
        customLinkSchema,
        t,
        'client.components.pages.CustomLinkPage',
        setCustomLink
      )}
    </Page>
  );
};

export default CustomLinkPage;
