import React, { useContext } from 'react';
import ImageInput from '../../imageInput';
import CroppedImage, { CropBox } from '../../../../models/CroppedImageModel';
import { AssetStoreContext } from '../../../hooks/AssetStoreProvider';

import './ThemeBackgroundImage.scss';

interface Props {
  croppedImage: CroppedImage;
  aspectRatio?: number;
  onImageURLChange: (imageURL: string, mimeType: string) => void;
  onImageURLDelete: () => void;
  onCrop: (cropBox: CropBox) => void;
  refresh: boolean;
}

const defaultProps: Partial<Props> = {
  aspectRatio: 1,
};

const ThemeBackgroundImage: React.FC<Props> = ({
  croppedImage,
  onImageURLChange,
  onImageURLDelete,
  onCrop,
  refresh,
}) => {
  const { uploadAsset } = useContext(AssetStoreContext);

  const addImage = async (image: File) => {
    try {
      const newUrl = await uploadAsset(image);
      onImageURLChange(newUrl, image.type);
    } catch (e) {
      const msg = 'Error while uploading file' + e.message;
      console.error(msg);
      throw new Error(msg);
    }
  };

  return (
    <div>
      <ImageInput
        key={`backgroundImage_${refresh}`}
        value={croppedImage?.url}
        onChange={addImage}
        remove={onImageURLDelete}
        enableCropper={true}
        onCrop={onCrop}
        cropData={croppedImage?.cropBox}
      />
    </div>
  );
};

ThemeBackgroundImage.defaultProps = defaultProps;
export default ThemeBackgroundImage;
