import React, { useState } from 'react';
import Page from '../ui/Page';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import ContactsModel, { contactsSchema } from '../../models/ContactsModel';
import PageBaseProps from './PageBase';
import { FormStateModel, isValidForm, makeSimpleForm } from '../utils/FormUtils';
import isEmpty from 'lodash/isEmpty';

const messages = defineMessages({
  title: 'Add To Contacts',
  desc: 'This feature will add an “Add to Contacts” button. This button allows your customer to save your contact information directly to their phone.',
  label: 'What do you want to name this section?',
  firstName: 'First Name',
  lastName: 'Last Name',
  role: 'Role/Job title',
  companyName: 'Company Name',
  phone: 'Phone',
  email: 'Email',
  website: 'Website',
});

const ContactsPage: React.FC<PageBaseProps<ContactsModel>> = (props) => {
  const { t } = useTranslation();
  const initialData = props.formData || {
    label: t(messages.title.id).toString(),
    firstName: '',
    lastName: '',
    role: '',
    companyName: '',
    phone: '',
    email: '',
    website: '',
  };
  const [contacts, setContacts] = useState<FormStateModel<ContactsModel>>({
    data: initialData,
    errors: {
      label: null,
      firstName: null,
      lastName: null,
      role: null,
      companyName: null,
      phone: null,
      email: null,
      website: null,
    },
    dirty: {
      label: !isEmpty(initialData.label),
      firstName: !isEmpty(initialData.firstName),
      lastName: !isEmpty(initialData.lastName),
      role: !isEmpty(initialData.role),
      companyName: !isEmpty(initialData.companyName),
      phone: !isEmpty(initialData.phone),
      email: !isEmpty(initialData.email),
      website: !isEmpty(initialData.website),
    },
  });
  const handleSubmit = () => {
    props.onSubmit({ key: 'contactsModel', data: contacts.data });
  };
  const enableSubmit = (): boolean => {
    return isValidForm<ContactsModel>(contacts.data, contactsSchema);
  };

  return (
    <Page
      title={t(messages.title.id)}
      desc={t(messages.desc.id)}
      enableSubmit={enableSubmit}
      handleSubmit={handleSubmit}
      isNewBlock={!props.formData}
    >
      {makeSimpleForm(
        contacts,
        contactsSchema,
        t,
        'client.components.pages.ContactsPage',
        setContacts
      )}
    </Page>
  );
};

export default ContactsPage;
