import { defineMessages } from '@vistaprint-org/digital-i18n-utils';

const messages = defineMessages({
  facebookPlaceHolder: 'e.g. https://www.facebook.com/USER_NAME',
  twitterPlaceHolder: 'userName',
  instagramPlaceHolder: 'userName',
  linkedinPlaceHolder: 'e.g. https://www.linkedin.com/in/USER_NAME',
  youtubePlaceHolder: 'e.g. https://www.youtube.com/channel/USER_NAME',
  vimeoPlaceHolder: 'e.g. https://vimeo.com/USER_NAME',
  soundcloudPlaceHolder: 'e.g. https://soundcloud.com/USER_NAME',
});

const SocialNetworks: {
  [key in SocialNetworkKey]: {
    baseUrl: string;
    type: 'url' | 'handle';
    help?: string;
    placeholder: string;
  };
} = {
  facebook: {
    baseUrl: 'https://facebook.com/',
    type: 'url',
    help: `https://www.facebook.com/help/1740158369563165/your-username`,
    placeholder: messages.facebookPlaceHolder.id,
  },
  twitter: {
    baseUrl: 'https://twitter.com/',
    type: 'handle',
    help: 'https://help.twitter.com/en/managing-your-account/change-twitter-handle#:~:text=Your%20username%20%E2%80%93%E2%80%93%20also%20known%20as,for%20you%20by%20your%20username.', // @TODO translate
    placeholder: messages.twitterPlaceHolder.id,
  },
  instagram: {
    baseUrl: 'https://instagram.com/',
    type: 'handle',
    help: `https://help.instagram.com/369207764224422?helpref=search&sr=1&query=username&search_session_id=a2bfb6977c13ab8b2cccdb96e625f8a7`,
    placeholder: messages.instagramPlaceHolder.id,
  },
  linkedin: {
    baseUrl: 'https://linkedin.com/',
    type: 'url',
    help: `https://www.linkedin.com/help/linkedin/answer/49315/find-your-linkedin-public-profile-url?lang=en`,
    placeholder: messages.linkedinPlaceHolder.id,
  },
  youtube: {
    baseUrl: 'https://youtube.com/',
    type: 'url',
    help: `https://support.google.com/youtube/answer/6180214?hl=en`,
    placeholder: messages.youtubePlaceHolder.id,
  },
  vimeo: {
    baseUrl: 'https://vimeo.com/',
    type: 'url',
    help: `https://vimeo.zendesk.com/hc/en-us/articles/224969888-Managing-your-Vimeo-profile#h_01EHQ20BH0A47Z0G1T92D5F8YC`,
    placeholder: messages.vimeoPlaceHolder.id,
  },
  soundcloud: {
    baseUrl: 'https://soundcloud.com/',
    type: 'url',
    help: `https://help.soundcloud.com/hc/en-us/articles/115003567968-Your-Display-Name-and-Profile-URL`,
    placeholder: messages.soundcloudPlaceHolder.id,
  },
};

export type SocialNetworkKey =
  | 'facebook'
  | 'twitter'
  | 'instagram'
  | 'linkedin'
  | 'youtube'
  | 'vimeo'
  | 'soundcloud';

export default SocialNetworks;
