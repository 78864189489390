import { FormDataModel, FormSchema } from '../components/utils/FormUtils';

type UploadPDFModel = FormDataModel<{
  label: string;
  fileName: string;
  url: string;
}>;

export const uploadPDFSchema: FormSchema<UploadPDFModel> = {
  label: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  fileName: {
    type: 'ignore',
    required: true,
    validator: null,
    validatorError: null,
  },
  url: {
    type: 'ignore',
    required: true,
    validator: null,
    validatorError: null,
  },
};

export default UploadPDFModel;
