import React from 'react';
import {
  GridContainer,
  Row,
  Column,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
  FluidImage,
} from '@vp/swan';

interface Props {
  patternURL: string;
  onPatternURLChange: (patternURL: string) => void;
}

const ThemeBackgroundImage: React.FC<Props> = ({ patternURL, onPatternURLChange }) => {
  const imagePatternUrls: string[] = [
    'pink_flower.jpg',
    'mountain_river.jpg',
    'pebbles.jpg',
    'mountain_yoga.jpg',
    'blue_water.jpg',
    'abstract_art_pink_blue.jpg',
    'sky_apartments_roof.jpg',
    'mountain_river_sky.jpg',
    'abstract_arts_blue_flowers.jpg',
    'abstract_art_blue_white_points.jpg',
    'abstract_art_sky_blue_pink_points.jpg',
  ];

  const imageContainer: JSX.Element[] = [];
  for (const imagePatternUrl of imagePatternUrls) {
    const fullUrl = `${window.vbcConfig.host}/images/${imagePatternUrl}`;
    imageContainer.push(
      <Column span={6} key={fullUrl} style={{ width: '50%' }}>
        <SelectionSetInput value={fullUrl}>
          <SelectionSetLabel>
            <FluidImage
              src={fullUrl}
              alt={imagePatternUrl.replace(/_/g, ' ').replace('.jpg', '')}
              role="img"
              aria-hidden="false"
            />
          </SelectionSetLabel>
        </SelectionSetInput>
      </Column>
    );
  }

  return (
    <div style={{ margin: 10 }}>
      <GridContainer>
        <SelectionSet
          defaultSelectedValue={patternURL}
          onSelectedValueChange={(newSelectedPattern) => {
            if (newSelectedPattern) {
              onPatternURLChange(newSelectedPattern);
            }
          }}
        >
          <Row>{imageContainer}</Row>
        </SelectionSet>
      </GridContainer>
    </div>
  );
};

export default ThemeBackgroundImage;
