import React, { ReactNode, useContext } from 'react';
import RouterContext, { RouteParams } from './RouterContext';

interface RouteProps {
  path: string;
  render?: (params: RouteParams) => ReactNode;
}

const Route: React.FC<RouteProps> = (props) => {
  const { path, render, children } = props;
  const context = useContext(RouterContext);

  const pathIsMatched = context.pathname.endsWith(path);

  if (render) {
    return <React.Fragment>{pathIsMatched && render(context.params)}</React.Fragment>;
  }
  return <React.Fragment>{pathIsMatched && children}</React.Fragment>;
};

export default Route;
