import React, { useRef, useState } from 'react';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';
import ReactCropper from 'react-cropper';
import { ModalDialog, ModalDialogContent, ControlIcon, Button, Box } from '@vp/swan';
import { CropBox } from '../../../models/CroppedImageModel';
import RotateIcon from '../../../images/rotate.svg';
import CropIcon from '../../../images/crop.svg';

import 'cropperjs/dist/cropper.css';
import './Cropper.scss';

interface CropperProps {
  src: string;
  onCrop?: (cropBox: CropBox) => void;
  cropData?: CropBox;
  visible: boolean;
  close: () => void;
}

const messages = defineMessages({
  done: 'Done',
  cropperModalLabel: 'Image Cropping Tool',
});

const Cropper: React.FC<CropperProps> = (props: CropperProps) => {
  const { t } = useTranslation();
  const cropperRef = useRef<HTMLImageElement>(null);
  const { src, onCrop, cropData, visible, close } = props;
  const [displayCrop, setDisplayCrop] = useState(!!(cropData?.width || cropData?.height));

  const getCropper = () => (cropperRef?.current as any)?.cropper;

  const rotate = () => {
    getCropper().rotate(-90);
  };

  const toggleCrop = () => {
    if (!displayCrop) {
      getCropper().cropped = false;
      getCropper().crop();
    } else {
      getCropper().clear();
      getCropper().cropped = true;
    }
    setDisplayCrop(!displayCrop);
  };

  const onCropperReady = () => {
    if (cropData) {
      getCropper().setData(cropData);
    }
    getCropper().cropped = true;
  };

  const onSubmit = () => {
    const { x, y, width, height, rotate } = getCropper().getData();
    onCrop?.({
      x,
      y,
      width: width ? width : undefined,
      height: height ? height : undefined,
      rotate,
    });
    close();
  };

  const onDismiss = () => {
    close();
    getCropper().setData(cropData);
  };

  const cropper = (
    <>
      <ReactCropper
        className="cropper-modal__react-cropper"
        src={src}
        ref={cropperRef}
        background={false}
        zoomable={true}
        scalable={true}
        movable={true}
        rotatable={true}
        aspectRatio={1}
        dragMode="move"
        toggleDragModeOnDblclick={false}
        autoCropArea={1}
        minContainerWidth={window.isMobile ? window.innerWidth : 550}
        minContainerHeight={window.isMobile ? window.innerHeight : 400}
        checkCrossOrigin={false}
        center={false}
        guides={false}
        ready={onCropperReady}
        viewMode={1}
        preview=".cropperjs-preview"
        accept={'image/*'}
      />
      <Box className="cropper-control-buttons">
        <Button sizeVariant="mini" onClick={onSubmit}>
          {t(messages.done.id)}
        </Button>
        <Button buttonShape="round" sizeVariant="mini">
          <ControlIcon iconType="close" onClick={onDismiss} />
        </Button>
      </Box>
      <Box className="cropper-edit-buttons">
        <RotateIcon onClick={rotate} />
        <CropIcon onClick={toggleCrop} />
      </Box>
    </>
  );

  return (
    <>
      <ModalDialog
        isOpen={visible}
        onRequestDismiss={onDismiss}
        style={{ display: visible ? 'block' : 'none' }}
      >
        <ModalDialogContent
          className="cropper-modal__content"
          aria-label={t(messages.cropperModalLabel.id)}
        >
          {visible && cropper}
        </ModalDialogContent>
      </ModalDialog>

      <div style={{ display: 'none' }}>{!visible && cropper}</div>
    </>
  );
};

export default Cropper;
