import React, { useContext, useState } from 'react';
import Page from '../ui/Page';
import { makeSimpleForm, isValidForm, FormStateModel } from '../utils/FormUtils';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import AboutModel, { aboutSchema } from '../../models/AboutModel';
import PageBaseProps from './PageBase';
import isEmpty from 'lodash/isEmpty';
import { SessionContext } from '../hooks/SessionContext';

const messages = defineMessages({
  title: 'About',
  desc: 'Add a quick bio for your customers',
  label: 'What do you want to name this section?',
  image: 'Your photo/logo',
  fullName: 'Name/Company Name',
  companyRole: 'Role at the company',
  bio: 'A little about yourself',
});

const AboutPage: React.FC<PageBaseProps<AboutModel>> = (props) => {
  const { t } = useTranslation();
  const { userData } = useContext(SessionContext);

  const initialData = props.formData || {
    label: t(messages.title.id).toString(),
    image: '',
    fullName: '',
    companyRole: '',
    bio: '',
  };
  const [about, setAbout] = useState<FormStateModel<AboutModel>>({
    data: initialData,
    errors: {
      label: null,
      image: null,
      fullName: null,
      companyRole: null,
      bio: null,
    },
    dirty: {
      label: !isEmpty(initialData.label),
      image: !isEmpty(initialData.image),
      fullName: !isEmpty(initialData.fullName),
      companyRole: !isEmpty(initialData.companyRole),
      bio: !isEmpty(initialData.bio),
    },
    meta: {
      userCanonicalId: userData.shopperId ?? userData.anonymousId,
    },
  });

  const handleSubmit = () => {
    props.onSubmit({ key: 'aboutModel', data: about.data });
  };
  const enableSubmit = (): boolean => {
    return isValidForm<AboutModel>(about.data, aboutSchema);
  };

  return (
    <Page
      title={t(messages.title.id)}
      desc={t(messages.desc.id)}
      enableSubmit={enableSubmit}
      handleSubmit={handleSubmit}
      isNewBlock={!props.formData}
    >
      {makeSimpleForm(about, aboutSchema, t, 'client.components.pages.AboutPage', setAbout)}
    </Page>
  );
};

export default AboutPage;
