/* eslint-disable no-undef */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import defaultsDeep from 'lodash/defaultsDeep';
import { i18nextDefaultConfig } from '@vistaprint-org/digital-i18n-utils';
import Pseudo from 'i18next-pseudo';

i18n
  .use(
    new Pseudo({
      languageToPseudo: 'en_us',
      enabled: !!(window.csbData && window.csbData.pseudoLoc),
    })
  )
  .use(initReactI18next)
  .init(
    defaultsDeep(
      {
        debug: false,
        resources: window.csbData.i18nStrings,
        lng: window.csbData ? window.csbData.language : 'en_us',
        fallbackLng: 'en_us',
        postProcess: ['pseudo'],
      },
      i18nextDefaultConfig
    )
  );
