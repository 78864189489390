export const iff = (cond: boolean, trueFunc: any, falseFunc: any) => {
  if (cond === true) {
    // eslint-disable-next-line lodash/prefer-lodash-typecheck
    if (typeof trueFunc === 'function') {
      return trueFunc();
    } else {
      return trueFunc;
    }
  } else {
    // eslint-disable-next-line lodash/prefer-lodash-typecheck
    if (typeof falseFunc === 'function') {
      return falseFunc();
    } else {
      return falseFunc;
    }
  }
};
