import React, { useState, useRef, useCallback, useContext } from 'react';
import update from 'immutability-helper';
import cloneDeep from 'lodash/cloneDeep';
import Router from './utils/Router';
import Pages from './data/Pages';
import PreviewIFrame from '../components/tower/PreviewIframe';
import Route from './utils/Route';
import SiteModel, { findPageModel, PageModel } from '../models/SiteModel';
import { updateSiteData } from '../api';
import MobileAddButton from './ui/MobileAddButton';
import { StepKind } from '../enums/PageEnums';
import QRPreviewButton from './ui/QRPreviewButton';
import MobileSwipeBar from './ui/MobileSwipeBar';
import { SessionContext } from './hooks/SessionContext';
import Onboarding from './onboarding/Onboarding';
import PreviewError from './tower/PreviewError';
import ErrorContext from './utils/ErrorContext';
import { trackFeatureEvent } from './utils/trackingUtils';

/* eslint-disable prettier/prettier */
import {
  MainPage,
  ContactsPage,
  ThemesPage,
  CallNowPage,
  AboutPage,
  SocialLinksPage,
  WebsitePage,
  CustomLinkPage,
  HoursPage,
  VideoPage,
  GalleryPage,
  UploadPDFPage,
  AddressPage,
  EmailUsPage,
} from './pages';
import {
  UploadPDFModel,
  ContactsModel,
  ThemesModel,
  CallNowModel,
  AboutModel,
  AddressModel,
  SocialLinksModel,
  WebsiteModel,
  GalleryModel,
  OperationHoursModel,
  EmailUsModel,
  CustomLinkModel,
  VideoModel,
} from '../models';
/* eslint-enable prettier/prettier */

import './App.scss';

interface Props {
  boom: () => void;
}

const App: React.FC<Props> = ({ boom }) => {
  const { userData, siteData, setSiteData } = useContext(SessionContext);
  const [tempSiteData, setTempSiteData] = useState<SiteModel>(siteData);
  const [isMobileEditorOpen, setIsMobileEditorOpen] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<StepKind>(StepKind.Features);
  const [onboardingStep, setOnboardingStep] = useState<number>(0);
  const [showAlert, setShowAlert] = React.useState(false);
  const editorDiv = useRef<HTMLDivElement>(null);

  const scrollEditorTop = () => {
    if (editorDiv.current) {
      editorDiv.current.scroll(0, 0);
    }
  };

  const nextOnboardingStep = () => {
    setOnboardingStep(onboardingStep + 1);
  };

  const handleSubmit = async (formData: PageModel) => {
    setIsMobileEditorOpen(false);

    const newFormData = cloneDeep(formData);
    let index;

    if ('data' in newFormData && 'id' in newFormData.data) {
      index = siteData.findIndex((p: PageModel) => {
        const currentData = p.data;
        const newData = newFormData.data;
        return (currentData as any).id === (newData as any).id;
      });
    } else {
      index = siteData.findIndex((p: PageModel) => p.key === newFormData.key);
    }

    const newSiteData = siteData.slice();

    if (index !== -1) {
      newSiteData.splice(index, 1, newFormData);
    } else {
      newSiteData.push(newFormData);
    }
    trackFeatureEvent(formData);
    await updateSiteData(userData.connectedProduct.altId, newSiteData);

    setSiteData(newSiteData);
    setTempSiteData(newSiteData);

    nextOnboardingStep();
  };

  const handleDelete = async (index: number) => {
    siteData.splice(index, 1);
    const newSite = siteData.slice();

    await updateSiteData(userData.connectedProduct.altId, newSite);
    setSiteData(newSite);
    setTempSiteData(newSite);
  };

  const handleReorder = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = tempSiteData[dragIndex];
      setTempSiteData(
        update(tempSiteData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [tempSiteData]
  );

  const confirmReorder = async () => {
    setSiteData(tempSiteData);
    await updateSiteData(userData.connectedProduct.altId, tempSiteData);
  };

  const onStepChange = (newSelectedStep: string) => {
    setCurrentTab(newSelectedStep === 'Features' ? StepKind.Features : StepKind.Style);
    if (StepKind.Style) {
      nextOnboardingStep();
    }
  };

  return (
    <>
      <Router>
        <Onboarding
          currentStep={onboardingStep}
          setStep={setOnboardingStep}
          setCurrentTab={setCurrentTab}
          boom={boom}
          scrollEditorTop={scrollEditorTop}
          setIsMobileEditorOpen={setIsMobileEditorOpen}
        />
        <div
          className={`editor-container-overlay ${isMobileEditorOpen ? 'open' : ''}`}
          onClick={() => setIsMobileEditorOpen(false)}
        />
        <div
          ref={editorDiv}
          id="editor-container"
          className={`editor-container ${isMobileEditorOpen ? 'open' : ''}`}
        >
          <MobileSwipeBar onClose={() => setIsMobileEditorOpen(false)} />
          <Route path={Pages.Main}>
            <MainPage
              onSubmit={handleSubmit}
              siteModel={tempSiteData}
              handleDelete={handleDelete}
              handleReorder={handleReorder}
              confirmReorder={confirmReorder}
              setCurrentTab={setCurrentTab}
              hideMobileEditor={() => setIsMobileEditorOpen(false)}
              onStepChange={onStepChange}
              currentTab={currentTab}
            />
          </Route>
          <Route path={Pages.Contacts}>
            <ContactsPage
              onSubmit={handleSubmit}
              formData={findPageModel('contactsModel', siteData)?.data as ContactsModel}
            />
          </Route>
          <Route path={Pages.Theme}>
            <ThemesPage
              onSubmit={handleSubmit}
              formData={siteData.find((k: any) => k.key === 'themesModel')?.data as ThemesModel}
            />
          </Route>
          <Route path={Pages.CallNow}>
            <CallNowPage
              onSubmit={handleSubmit}
              formData={findPageModel('callNowModel', siteData)?.data as CallNowModel}
            />
          </Route>
          <Route path={Pages.EmailUs}>
            <EmailUsPage
              onSubmit={handleSubmit}
              formData={findPageModel('emailUsModel', siteData)?.data as EmailUsModel}
            />
          </Route>
          <Route path={Pages.About}>
            <AboutPage
              onSubmit={handleSubmit}
              formData={findPageModel('aboutModel', siteData)?.data as AboutModel}
            />
          </Route>
          <Route
            path={Pages.Social}
            render={(params) => {
              let data;

              if ('id' in params) {
                const socialLinksModelIdx = siteData.findIndex((model: any) => {
                  return (model.data as SocialLinksModel).id === params?.id;
                });
                data = socialLinksModelIdx !== -1 ? siteData[socialLinksModelIdx].data : undefined;
              }

              return (
                <SocialLinksPage onSubmit={handleSubmit} formData={data as SocialLinksModel} />
              );
            }}
          />
          <Route path={Pages.Website}>
            <WebsitePage
              onSubmit={handleSubmit}
              formData={findPageModel('websiteModel', siteData)?.data as WebsiteModel}
            />
          </Route>
          <Route path={Pages.Gallery}>
            <GalleryPage
              onSubmit={handleSubmit}
              formData={findPageModel('galleryModel', siteData)?.data as GalleryModel}
            />
          </Route>
          <Route
            path={Pages.CustomLink}
            render={(params) => {
              let data;

              if ('id' in params) {
                const customLinkModelIdx = siteData.findIndex((model: any) => {
                  return (model.data as CustomLinkModel).id === params?.id;
                });
                data = customLinkModelIdx !== -1 ? siteData[customLinkModelIdx].data : undefined;
              }

              return <CustomLinkPage onSubmit={handleSubmit} formData={data as CustomLinkModel} />;
            }}
          />
          <Route path={Pages.Hours}>
            <HoursPage
              onSubmit={handleSubmit}
              formData={findPageModel('operationHoursModel', siteData)?.data as OperationHoursModel}
            />
          </Route>
          <Route path={Pages.Video}>
            <VideoPage
              onSubmit={handleSubmit}
              formData={findPageModel('videoModel', siteData)?.data as VideoModel}
            />
          </Route>
          <Route path={Pages.UploadPDF}>
            <UploadPDFPage
              onSubmit={handleSubmit}
              formData={findPageModel('uploadPDFModel', siteData)?.data as UploadPDFModel}
            />
          </Route>
          <Route path={Pages.Address}>
            <AddressPage
              onSubmit={handleSubmit}
              formData={findPageModel('addressModel', siteData)?.data as AddressModel}
            />
          </Route>
        </div>
      </Router>
      <ErrorContext.Provider value={{ setShowAlert }}>
        <div className="iframe-container">
          {showAlert && <PreviewError />}
          <div className="iframe-content">
            <PreviewIFrame previewParams={siteData} />
            <QRPreviewButton />
          </div>
        </div>
        <MobileAddButton
          onClick={() => {
            setIsMobileEditorOpen(true);
            setTimeout(nextOnboardingStep, 350);
          }}
          hidden={isMobileEditorOpen}
        />
      </ErrorContext.Provider>
    </>
  );
};

export default App;
