import React, { useState } from 'react';
import { ColorResult } from 'react-color';
import { ColorWrapChangeHandler } from 'react-color/lib/components/common/ColorWrap';

import { Typography } from '@vp/swan';
import { ColorSwatch, ColorSwatches } from '@vp/swan';
import ColorPicker from './ColorPicker';

import './ColorPalette.scss';

interface Props {
  color: string;
  labelText: string;
  onColorChange: (color: string) => void;
}

const ColorPalette: React.FC<Props> = ({ color, onColorChange, labelText }) => {
  const [customColor, setCustomColor] = useState<string>(color);
  const [colorPickerVisible, setColorPickerVisible] = useState<boolean>(false);

  const onChange = (color: ColorResult) => {
    setCustomColor(color.hex.replace('#', ''));
  };

  const onApply = () => {
    setColorPickerVisible(false);

    if (color !== customColor) {
      onColorChange(customColor);
    }
  };

  return (
    <div className="colorpalette-container">
      <div className="colorpalette-label">
        <Typography>{labelText}</Typography>
        <ColorSwatches role="radiogroup" sizeVariant="super">
          <ColorSwatch
            primaryColor={`#${color}`}
            title={`color: ${color}`}
            onClick={() => setColorPickerVisible(true)}
            role="radio"
            aria-label={`#${color}`}
            aria-checked={colorPickerVisible}
          />
        </ColorSwatches>
      </div>
      {colorPickerVisible && (
        <ColorPicker
          color={customColor}
          onChange={onChange as ColorWrapChangeHandler}
          onApply={onApply}
        />
      )}
    </div>
  );
};

export default ColorPalette;
