import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { isValidPhoneNumber } from 'react-phone-number-input';

defineMessages({
  required: 'This field is required',
  email: 'Please enter a valid email address',
  phone: 'Please enter a valid phone number',
  website: 'Please enter a valid website',
  video: 'Please enter a valid youtube or vimeo url',
  genericError: 'Something wrong happened', // @TODO this should be removed once File Uploads are finalized
});

function isEmail(value: string | number) {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !value || reg.test(String(value).toLowerCase());
}

function isPhone(value: string | number) {
  return !value || isValidPhoneNumber(value as string);
}

function isWebsite(value: string | number) {
  const reg =
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
  return !value || reg.test(String(value).toLowerCase());
}

function isVideo(value: string | number) {
  const youtube =
    /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?/;
  const vimeo = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  return (
    !value || youtube.test(String(value).toLowerCase()) || vimeo.test(String(value).toLowerCase())
  );
}

export { isEmail, isPhone, isWebsite, isVideo };
