import React, { useState } from 'react';
import Page from '../ui/Page';
import { useTranslation } from 'react-i18next';
import EmailUsModel, { emailUsSchema } from '../../models/EmailUsModel';
import PageBaseProps from './PageBase';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { makeSimpleForm, isValidForm, FormStateModel } from '../utils/FormUtils';
import isEmpty from 'lodash/isEmpty';

const messages = defineMessages({
  title: 'Email us',
  desc: 'Add an email address you would like to be contacted at',
  label: 'What do you want to name this section?',
  email: 'E-mail',
});

const EmailUsPage: React.FC<PageBaseProps<EmailUsModel>> = (props) => {
  const { t } = useTranslation();
  const initialData = props.formData || {
    label: t(messages.title.id).toString(),
    email: '',
  };
  const [emailUs, setEmailUs] = useState<FormStateModel<EmailUsModel>>({
    data: initialData,
    errors: {
      label: null,
      email: null,
    },
    dirty: {
      label: !isEmpty(initialData.label),
      email: !isEmpty(initialData.email),
    },
  });

  const handleSubmit = () => {
    props.onSubmit({ key: 'emailUsModel', data: emailUs.data });
  };

  const enableSubmit = (): boolean => {
    return isValidForm<EmailUsModel>(emailUs.data, emailUsSchema);
  };

  return (
    <Page
      title={t(messages.title.id)}
      desc={t(messages.desc.id)}
      enableSubmit={enableSubmit}
      handleSubmit={handleSubmit}
      isNewBlock={!props.formData}
    >
      {makeSimpleForm(emailUs, emailUsSchema, t, 'client.components.pages.EmailUsPage', setEmailUs)}
    </Page>
  );
};

export default EmailUsPage;
