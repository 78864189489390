import { FormDataModel, FormSchema } from '../components/utils/FormUtils';

type GalleryModel = FormDataModel<{
  label: string;
  images: string[];
}>;

export const gallerySchema: FormSchema<GalleryModel> = {
  label: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  images: {
    type: 'ignore',
    required: true,
    validator: null,
    validatorError: null,
  },
};

export default GalleryModel;
