import React, { useState } from 'react';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import copy from 'copy-to-clipboard';
import {
  Button,
  Link,
  Typography,
  FlexBox,
  FluidImage,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalDialogTitle,
  ModalDialogCloseButton,
} from '@vp/swan';

import './PublishModal.scss';

interface Props {
  isModalOpened: boolean;
  setModalStatus: (status: string | null) => void;
  publishedDomain: string;
}
const messages = defineMessages({
  congrats: 'Congratulations! your VistaConnect site is published',
  changesLive: 'Your changes are now live. To check your site, scan the QR code below.',
  changesLiveMobile: 'Your changes are now live. To check your site, click the link below.',
  confettiAlt: 'Confetti',
  return: 'Return to studio',
  view: 'View live version',
  copyLink: 'Copy Link',
  linkCopied: 'Link copied!',
  close: 'Close',
});

const PublishModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isModalOpened, setModalStatus, publishedDomain } = props;
  const [copied, setCopied] = useState(false);

  const onClickCopy = () => {
    copy(publishedDomain);
    setCopied(true);
  };

  return (
    <ModalDialog
      isOpen={isModalOpened}
      onRequestDismiss={() => setModalStatus(null)}
      className="publish-modal"
    >
      <ModalDialogContent aria-label="dialog-content">
        <ModalDialogCloseButton visuallyHiddenLabel={t(messages.close.id)} />
        <FluidImage
          className="confetti-image"
          src={`${window.vbcConfig.host}/images/confetti.svg`}
          alt={t(messages.confettiAlt.id)}
        />
        <ModalDialogHeader>
          <ModalDialogTitle>
            <div className="title-content">{t(messages.congrats.id)}</div>
          </ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody>
          <FlexBox alignItems="center" flexDirection="column" m={4}>
            <Typography fontSize={3} mb={4} align="center">
              {window.isMobile ? t(messages.changesLiveMobile.id) : t(messages.changesLive.id)}
            </Typography>
          </FlexBox>
        </ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons>
            <div className="qr-code-container">
              <QRCode size={80} value={publishedDomain} />
            </div>
            <Button
              render={(props) => (
                <Link
                  className="swan-button swan-button-skin-secondary view-site-button"
                  to={publishedDomain}
                >
                  {props.children}
                </Link>
              )}
            >
              {t(messages.view.id)}
            </Button>

            <Button
              skin={copied ? 'secondary' : 'primary'}
              onClick={onClickCopy}
              className="copy-link-button"
            >
              {!copied ? t(messages.copyLink.id) : t(messages.linkCopied.id)}
            </Button>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  );
};

export default PublishModal;
