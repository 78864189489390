import { FormDataModel, FormSchema } from '../components/utils/FormUtils';
import { isPhone } from '../components/utils/ValidationUtils';

type CallNowModel = FormDataModel<{
  phone: string;
  label: string;
}>;

export const callNowSchema: FormSchema<CallNowModel> = {
  label: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  phone: {
    type: 'phone',
    required: true,
    validator: isPhone,
    validatorError: 'client.components.utils.ValidationUtils.phone',
  },
};

export default CallNowModel;
