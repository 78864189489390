import React, { ChangeEvent } from 'react';
import { Buttonbar, ButtonbarButton, ButtonbarButtonLabel } from '@vp/swan';
import { StepKind } from '../../../enums/PageEnums';
import { useTranslation } from 'react-i18next';

import './MainButtonBar.scss';

interface Props {
  selectedStep: StepKind;
  onStepChange: (newSelectedStep: string, event: ChangeEvent<HTMLInputElement>) => void;
}

const MainButtonBar: React.FC<Props> = ({ selectedStep, onStepChange }) => {
  const { t } = useTranslation();

  const buttonbarButtons: JSX.Element[] = [];
  Object.keys(StepKind).forEach((label) => {
    buttonbarButtons.push(
      <ButtonbarButton key={label} value={label}>
        <ButtonbarButtonLabel>{t(`client.components.pages.Main.${label}`)}</ButtonbarButtonLabel>
      </ButtonbarButton>
    );
  });

  return (
    <section className="editor-section button-bar-section">
      <Buttonbar
        variant="single-select"
        widthVariant="full-width"
        selectedValue={StepKind[selectedStep]}
        onSelectedValueChange={(newSelectedStep, event) => {
          if (newSelectedStep) {
            onStepChange(newSelectedStep, event);
          }
        }}
      >
        {buttonbarButtons}
      </Buttonbar>
    </section>
  );
};
export default MainButtonBar;
