import React, { useState, useEffect, useContext } from 'react';

import {
  trackPageViewed,
  analyticsDataRegistry,
  getTrackingConfiguration,
  TrackingConfiguration,
  EnvironmentToTrackingMap,
  trackProductViewed,
} from '@vp/digital-tracking-lib';
import { SessionContext } from './SessionContext';

interface AnalyticsProviderModel {
  trackBuilderPageLoad(): void;
  trackBuilderProductViewed(): void;
  trackingConfigData: TrackingConfiguration;
  trackingKeys: EnvironmentToTrackingMap | undefined;
}

export let trackingAssetName: string | undefined;

const AnalyticsContext = React.createContext<AnalyticsProviderModel>({} as AnalyticsProviderModel);

function getVariant(merchandisingSelections: Record<string, string> | undefined) {
  if (!merchandisingSelections) {
    return undefined;
  }
  return Object.keys(merchandisingSelections)
    .map((key: string) => `${key}:${merchandisingSelections[key]}`)
    .join('_');
}

const AnalyticsProvider = (props: any) => {
  if (process.env.NODE_ENV === 'development') {
    return <>{props.children}</>;
  }

  const [trackingKeys, setTrackingKeys] = useState<EnvironmentToTrackingMap>();
  const { connectedBusinessCardsStudio } = analyticsDataRegistry;
  const { userData } = useContext(SessionContext);

  const trackingConfigData: TrackingConfiguration = getTrackingConfiguration(
    connectedBusinessCardsStudio!
  );

  trackingAssetName = connectedBusinessCardsStudio?.trackingAssetName;

  const trackBuilderPageLoad = () => {
    trackPageViewed(connectedBusinessCardsStudio!);
  };

  const trackBuilderProductViewed = () => {
    if (userData) {
      const variant = getVariant(userData.connectedProduct.studioData.data.selectedOptionValues);
      const page = { ...connectedBusinessCardsStudio!, brand: 'NFC', variant };
      trackProductViewed(page);
    }
  };

  useEffect(() => {
    const init = () => {
      // Get Tracking Keys
      setTrackingKeys(window.csbData.trackingKeys);
    };

    init();
  }, []);

  return (
    <AnalyticsContext.Provider
      value={{
        trackBuilderPageLoad,
        trackBuilderProductViewed,
        trackingConfigData,
        trackingKeys,
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsContext, AnalyticsProvider };
