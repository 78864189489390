import React from 'react';

import './MobileAddButton.scss';

interface MobileAddButtonProps {
  onClick: () => void;
  hidden: boolean;
}

const MobileAddButton: React.FC<MobileAddButtonProps> = (props: MobileAddButtonProps) => {
  return (
    <div className={`mobile-add-button ${props.hidden ? 'hidden' : ''}`} onClick={props.onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.661 16.168L5.711 18.29L7.833 13.339L16.672 4.5L19.5 7.329L10.661 16.168Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M17.66 9.16L14.84 6.34" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.66 16.17L7.82999 13.34" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

export default MobileAddButton;
