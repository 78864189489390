import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { VistaprintAuth } from '@vp/auth-react';
import './i18n';

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require('@axe-core/react');
  const axeConfig = {
    rules: [
      {
        id: 'duplicate-id',
        enabled: false,
      },
      {
        id: 'html-lang-valid',
        enabled: false,
      },
      {
        id: 'link-name',
        enabled: false,
      },
      {
        id: 'aria-allowed-role',
        enabled: false,
      },
      {
        id: 'aria-valid-attr-value',
        enabled: false,
      },
      {
        id: 'empty-heading',
        enabled: false,
      },
      {
        id: 'region',
        enabled: false,
      },
      {
        id: 'page-has-heading-one',
        enabled: false,
      },
    ],
  };
  axe(React, ReactDOM, 1000, axeConfig);
}
const urlSearchParams: any = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const isCareAgent = params.care_agent;
const redirectionInProgress = params.code && params.state;
let config;
if (isCareAgent) {
  window.localStorage.setItem('isCareAgent', 'true');
}
if (window.localStorage.getItem('isCareAgent')) {
  config = {
    developmentMode: {
      clientID: window.vbcConfig.careConsoleClientId,
      redirectUriOrigin: `${window.location.origin}/${window.csbData.culture}/studio`,
    },
    options: {
      connection: 'vistaprintcom-waad',
    },
    enableCrossSiteSso: true,
    culture: 'en-US',
  };
} else {
  config = {
    culture: window.csbData.culture,
    developmentMode:
      window.vbcConfig.env === 'production'
        ? false
        : {
            clientID: window.vbcConfig.auth0ClientId,
            redirectUriOrigin: `${window.location.origin}`,
          },
  };
}
VistaprintAuth.init(config).then((identity) => {
  if (identity.isSignedIn) {
    window.localStorage.removeItem('isCareAgent');
  }
  if (
    window.localStorage.getItem('isCareAgent') &&
    !identity.isSignedIn &&
    !redirectionInProgress
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const auth = new VistaprintAuth.WebAuth();
    auth.signIn();
  } else {
    ReactDOM.render(<App />, document.getElementById('root'));
  }
});
