import { FormDataModel, FormSchema } from '../components/utils/FormUtils';
import { isWebsite } from '../components/utils/ValidationUtils';

type WebsiteModel = FormDataModel<{
  url: string;
  label: string;
}>;

export const websiteSchema: FormSchema<WebsiteModel> = {
  label: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  url: {
    type: 'text',
    required: true,
    validator: isWebsite,
    validatorError: 'client.components.utils.ValidationUtils.website',
  },
};

export default WebsiteModel;
