import React from 'react';
import ReactConfetti from 'react-dom-confetti';

import './Confetti.scss';

interface ConfettiProps {
  enable: boolean;
}

const Confetti: React.FC<ConfettiProps> = (props: ConfettiProps) => {
  return (
    <div className="confetti-bag">
      <div className="confetti">
        <ReactConfetti
          active={props.enable}
          config={{
            angle: 90,
            spread: 360,
            startVelocity: 50,
            elementCount: 100,
            dragFriction: 0.1,
            duration: 10000,
            stagger: 1,
            width: '5px',
            height: '10px',
            colors: ['#295E9D', '#44A2DD', '#73D2F2'],
          }}
        />
      </div>
    </div>
  );
};

export default Confetti;
