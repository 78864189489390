import { H3 } from '@vp/swan';
import React from 'react';
import ButtonHeader from './ButtonHeader';

import './Page.scss';

interface PageProps {
  title?: string;
  desc?: string;
  enableSubmit: () => boolean;
  handleSubmit: () => void;
  isNewBlock?: boolean;
}

const Page: React.FC<PageProps> = (props: React.PropsWithChildren<PageProps>) => {
  return (
    <React.Fragment>
      <ButtonHeader
        enableSubmit={props.enableSubmit}
        handleSubmit={props.handleSubmit}
        isNewBlock={props.isNewBlock}
      />
      {props.title && (
        <H3 fontWeight="bolder" className="page-title">
          {props.title}
        </H3>
      )}
      {props.desc && <p className="page-desc">{props.desc}</p>}

      <section className="editor-section">{props.children}</section>
    </React.Fragment>
  );
};

export default Page;
