import { FormDataModel, FormSchema } from '../components/utils/FormUtils';
import { isVideo } from '../components/utils/ValidationUtils';

type VideoModel = FormDataModel<{
  url: string;
  label: string;
}>;

export const videoSchema: FormSchema<VideoModel> = {
  label: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  url: {
    type: 'text',
    required: true,
    validator: isVideo,
    validatorError: 'client.components.utils.ValidationUtils.video',
    placeholder: 'client.components.pages.VideoPage.videoPlaceHolder',
  },
};

export default VideoModel;
