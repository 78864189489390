import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { Button, Typography } from '@vp/swan';

import './CustomTooltip.scss';

const messages = defineMessages({
  next: 'Next',
  finish: 'Finish tour and continue building',
});

const CustomTooltip: React.FC<TooltipRenderProps> = (props: TooltipRenderProps) => {
  const { step, isLastStep, primaryProps, tooltipProps } = props;
  const requiredAction = (step as any).requiredAction;
  const { t } = useTranslation();

  return (
    <div className="onboarding-tooltip" {...tooltipProps}>
      {step.title && <h4 className="onboarding-tooltip__title">{step.title}</h4>}
      <p className="onboarding-tooltip__content">{step.content}</p>
      <div className="onboarding-tooltip__footer">
        {window.isMobile && !isLastStep && (
          <Button
            className="onboarding-tooltip__footer__end__button"
            sizeVariant="mini"
            onClick={props.skipProps.onClick}
          >
            {t('client.components.onboarding.Onboarding.endTour')}
          </Button>
        )}
        {!step.hideFooter && (
          <Button
            className="onboarding-tooltip__footer__button"
            skin="primary"
            sizeVariant="mini"
            {...primaryProps}
          >
            {isLastStep ? t(messages.finish.id) : t(messages.next.id)}
          </Button>
        )}
        {requiredAction && (
          <Typography
            className="onboarding-tooltip__footer__action"
            textColor="dark-grey"
            fontSize={-1}
          >
            {requiredAction}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default CustomTooltip;
