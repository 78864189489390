import React, { useContext, useEffect, useRef } from 'react';
import { Spinner } from '@vp/swan';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';
import SiteModel from '../../models/SiteModel';
import { preview } from '../../api';
// import config from '../../config';

import { SessionContext } from '../hooks/SessionContext';
import ErrorContext from '../utils/ErrorContext';

import './PreviewIframe.scss';
import { iff } from '../../iff';
import { useLocale } from '@vp/digital-locale-lib';

interface PreviewIFrameProps {
  previewParams: SiteModel;
}

const LOADING_STATE = 'loading';
const ERROR_STATE = 'error';

const messages = defineMessages({
  loadingAria: 'Loading',
  error: 'Oops, we’re having trouble loading your page.',
  retry: 'Try Again',
});

const PreviewIFrame: React.FC<PreviewIFrameProps> = (props: PreviewIFrameProps) => {
  const iframeRef = useRef(null);

  const { t } = useTranslation();
  const [srcDoc, setSrcDoc] = React.useState(LOADING_STATE);
  const { userData } = useContext(SessionContext);
  const { setShowAlert } = useContext(ErrorContext);
  const locale = useLocale();

  const language = locale.languageCode.toLowerCase();

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data === 'Iframe is ready') {
        console.log('Received Iframe is ready child component');

        // Send reply back to child component
        const eventData = {
          type: 'iframePreviewUpdate',
          message: [userData.connectedProduct.studioData, ...userData.connectedProduct.siteModel],
        };
        event.source.postMessage(eventData, event.origin);
      }
    };
    if (userData.connectedProduct.isRenderrushSite === true) {
      // Listen for messages from child component
      window.addEventListener('message', handleMessage);
    }

    return () => {
      // Clean up event listener on component unmount
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  React.useEffect(() => {
    async function getSrcDoc() {
      try {
        const res = await preview(userData.connectedProduct.altId, props.previewParams);
        setSrcDoc(res.data.data);
      } catch (e) {
        setShowAlert(true);
        setSrcDoc(ERROR_STATE);
      }
    }

    if (userData.connectedProduct.isRenderrushSite === true) {
      console.log('sending data to iframe');
      const eventData = {
        type: 'iframePreviewUpdate',
        ///message: [userData.connectedProduct.studioData, ...userData.connectedProduct.siteModel],
        message: [userData.connectedProduct.studioData, ...props.previewParams],
      };
      if (iframeRef !== null) {
        const iframeRefCurrent = iframeRef.current as unknown as HTMLIFrameElement;
        if (iframeRefCurrent.contentWindow !== null) {
          iframeRefCurrent.contentWindow.postMessage(eventData, '*');
        }
      }
    } else {
      setSrcDoc(LOADING_STATE);
      getSrcDoc();
    }
  }, [props.previewParams]);

  const renderrushIframeUrl = `${window.vbcConfig.renderrushUrl}/iframe?language=${language}`;

  return (
    <div className="site-preview-frame-container">
      <div className="mobile-device-element mobile-up-volume" />
      <div className="mobile-device-element mobile-down-volume" />
      <div className="mobile-device-element mobile-lock" />
      <div className="mobile-device-element mobile-speaker" />
      <div className="mobile-device-element mobile-camera" />
      {iff(
        userData.connectedProduct.isRenderrushSite === true,
        () => {
          return (
            <div style={{ width: '100%', height: '100%' }}>
              <iframe
                ref={iframeRef}
                className="site-preview-frame"
                title={'sitePreviewIframe'}
                src={renderrushIframeUrl}
              />
            </div>
          );
        },
        () => {
          if (srcDoc === LOADING_STATE) {
            return (
              <div className="site-preview-frame-overlay">
                <Spinner accessibleText={t(messages.loadingAria.id)} />
              </div>
            );
          } else {
            if (srcDoc !== ERROR_STATE) {
              return (
                <iframe
                  className="site-preview-frame"
                  title={'sitePreviewIframe'}
                  srcDoc={srcDoc}
                />
              );
            }
          }
        }
      )}
    </div>
  );
};

export default PreviewIFrame;
