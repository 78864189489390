import React from 'react';
import RouterContext, { RouteParams } from './RouterContext';
import OnboardingContext from './OnboardingContext';
import Pages from '../data/Pages';

interface RouterState {
  pathname: string;
  params?: RouteParams;
}

class Router extends React.Component<React.PropsWithChildren<Record<never, never>>, RouterState> {
  static contextType = OnboardingContext;

  constructor(props: Record<never, never>) {
    super(props);

    this.state = {
      pathname: '/',
    };
  }

  render(): JSX.Element {
    const changePage = (path: string, params?: RouteParams) => {
      this.setState({
        pathname: path,
        params,
      });
      if (path !== Pages.Main) {
        this.context.nextOnboardingStep();
      }
    };

    return (
      <RouterContext.Provider
        value={{
          pathname: this.state.pathname,
          params: this.state.params || {},
          changePage,
        }}
      >
        {this.props.children}
      </RouterContext.Provider>
    );
  }
}

export default Router;
