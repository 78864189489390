import VistaprintAuth from '@vp/auth';
import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { v4 as uuidv4 } from 'uuid';

axios.interceptors.request.use((config) => {
  if (
    config.headers &&
    !config.url?.startsWith('https://bookends.') &&
    !config.url?.startsWith('https://siteflow.')
  ) {
    config.headers['x-request-id'] = uuidv4();
  }
  return config;
});

const vpAccessTokenRequest = async <T>(config: AxiosRequestConfig): Promise<AxiosPromise> => {
  const auth = new (VistaprintAuth.WebAuth as any)();
  const authHeader = auth.getAuthorizationHeader();

  if (!authHeader) {
    console.error('no vp-access-token found');
    throw Error('no vp-access-token found');
  }
  const configWithAuthHeader = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: authHeader,
    },
  };
  return axios(configWithAuthHeader) as AxiosPromise<T>;
};

export default axios;

export { vpAccessTokenRequest };
