import { FormDataModel, FormSchema } from '../components/utils/FormUtils';

// @TODO translate
export enum MapStyle {
  roadmap = 'roadmap',
  satellite = 'satellite',
  terrain = 'terrain',
  hybrid = 'hybrid',
}

export function getMapStyleByValue(val: string): MapStyle {
  return (<any>MapStyle)[val];
}

type AddressModel = FormDataModel<{
  label: string;
  businessName: string;
  address: string;
  mapStyle: MapStyle;
  enableMap: boolean;
  lat: number;
  lng: number;
}>;

export const addressSchema: FormSchema<AddressModel> = {
  label: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  businessName: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  address: {
    type: 'text',
    required: true,
    validator: null,
    validatorError: null,
  },
  enableMap: {
    type: 'ignore',
    required: false,
    validator: null,
    validatorError: null,
  },
  mapStyle: {
    type: 'ignore',
    required: false,
    validator: null,
    validatorError: null,
  },
  lat: {
    type: 'ignore',
    required: false,
    validator: null,
    validatorError: null,
  },
  lng: {
    type: 'ignore',
    required: false,
    validator: null,
    validatorError: null,
  },
};

export default AddressModel;
