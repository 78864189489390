import React from 'react';
import './BookendsHeader.scss';
import {
  TrackingConfiguration,
  getTrackingConfiguration,
  analyticsDataRegistry,
} from '@vp/digital-tracking-lib';
import { Header } from '@vp/esi-bookends';

const { connectedBusinessCardsStudio } = analyticsDataRegistry;

const trackingConfiguration: TrackingConfiguration = getTrackingConfiguration(
  connectedBusinessCardsStudio!
);

// @TODO: these should go into a centralized config or Context
const tenant = process.env.NODE_ENV === 'production' ? 'vistaprint' : 'vistaprint-dev';
const MemoizedBookend: React.FC = () => {
  return (
    <div className="bookends-header">
      <Header
        locale={window.csbData.culture}
        tenant={tenant}
        trackingConfiguration={trackingConfiguration}
        type="slim"
      />
    </div>
  );
};
export default React.memo(MemoizedBookend);
