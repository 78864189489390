import React, { useContext, useState } from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import MobilePortraitIcon from '../../images/mobile-portrait.svg';
import { SessionContext } from '../hooks/SessionContext';

import './QRPreviewButton.scss';

const messages = defineMessages({
  scanForLivePreview: 'Scan for a live preview',
});

const QRPreviewButton: React.FC = () => {
  const { t } = useTranslation();
  const { userData } = useContext(SessionContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggle = () => {
    setExpanded(!expanded);
  };

  const renderrushUrl = window.vbcConfig.renderrushUrl;
  let url = `${window.vbcConfig.host}/preview/${userData.connectedProduct.altId}`;
  if (userData.connectedProduct.isRenderrushSite === true) {
    url = `${renderrushUrl}/mobile-preview?url=${window.vbcConfig.host}/preview/json/${userData.connectedProduct.altId}`;
  }

  const panel = (
    <div className="live-preview-qr-panel">
      <span>{t(messages.scanForLivePreview.id)}</span>
      <QRCode size={80} value={url} />
    </div>
  );

  const button = (
    <div className="live-preview-qr-button">
      <MobilePortraitIcon className="live-preview-qr-button-icon" />
    </div>
  );

  return (
    <div className="live-preview">
      <div className="live-preview-small-screen" onClick={toggle}>
        {expanded ? panel : button}
      </div>
      <div className="live-preview-big-screen">{panel}</div>
    </div>
  );
};

export default QRPreviewButton;
