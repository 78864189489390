import React, { useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button, Icon, SwanIconKey } from '@vp/swan';
import Pages from '../data/Pages';
import RouterContext from '../utils/RouterContext';
import { defineMessages } from '@vistaprint-org/digital-i18n-utils';
import { useTranslation } from 'react-i18next';

interface LinkButtonProps {
  to?: Pages;
  text?: string;
  iconType?: SwanIconKey;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
const messages = defineMessages({
  buttonAriaLabel: 'Back Button',
});

const LinkButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
  const { changePage } = useContext(RouterContext);
  const { t } = useTranslation();
  return (
    <Button
      aria-label={t(messages.buttonAriaLabel.id)}
      role="button"
      buttonShape="round"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        if (props.onClick) {
          props.onClick(e);
        }
        if (props.to) {
          changePage(props.to);
        }
      }}
    >
      {!isEmpty(props.text) && props.text}
      {!isEmpty(props.iconType) && <Icon iconType={props.iconType} />}
    </Button>
  );
};

export default LinkButton;
